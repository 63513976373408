import { createContext } from "react";
import { action, observable, runInAction } from "mobx";
import District from "../../models/District";
import axios from "../../axios";
import City from "../../models/City";
import { isUndefined } from "lodash";

class RegisterStore {
  @observable token = localStorage.getItem("token");
  @observable districs: District[] = [];
  @observable region: City[] = [];
  @observable cities: City[] = [];

  // Response status
  @observable isLoading: boolean = false;
  @observable districtLoading: boolean = false;

  @observable isError: boolean = false;
  @observable errors: string = "";

  config = {
    headers: {
      Authorization: `Bearer ${this.token}`,
      Accept: "application/json",
    },
  };
  // Get city & neighbourhood calls -- START
  @action
  getDistrict = (setDistrict?: any) => {
    this.districtLoading = true;
    axios
      .get("/V1/district/showActiveDistrict", {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response) => {
        // @on Success
        runInAction(() => {
          this.districs = response.data;
          setDistrict(this.districs);
          this.districtLoading = false;
        });
      })
      .catch((_error) => {
        // @On failure
        this.isError = true;
      })
      .finally(() => {
        this.districtLoading = false;
      });
  };

  // Get city & neighbourhood calls -- START
  @action
  getCity = (setCity?: any) => {
    this.isLoading = true;
    axios
      .get("/V1/city/showActiveCity", {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response) => {
        // @on Success
        runInAction(() => {
          this.cities = response.data;
          setCity(this.cities);
        });
      })
      .catch((_error) => {
        // @On failure
        this.isError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  @action
  getRegion = (setRegion?: any) => {
    axios
      .get("/V1/region/showActiveRegion", {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response) => {
        // @on Success
        let arr: City[] = response.data;
        runInAction(() => {
          this.region = arr;
          if (!isUndefined(setRegion)) {
            setRegion(this.region);
          }
        });
      })
      .catch((_error) => {
        // @On failure
      })
      .finally(() => {});
  };
}

export const registerStore = new RegisterStore();
const RegisterStoreContext = createContext(new RegisterStore());
export default RegisterStoreContext;
