import {createContext} from "react";
import {action, observable, runInAction} from "mobx";
import axios from "../axios";
import {message, notification} from "antd";
import i18next from "i18next";
import City from "../models/City";
import District from "../models/District";
import {Fee, Taxes} from "../models/Taxes";
import WalletHistory from "../models/WalletHistory"
import {
    IModelMaximumValue,
    IModelMaximumWeight,
} from "../models/IModelMaximum";

const error = (value: any) => {
    message.error(value);
};

const openNotificationWithIconError = (message: string, descr: string) => {
    notification["error"]({
        message: message,
        description: descr,
    });
};

const openNotificationWithIconSuccess = (message: string, descr: string) => {
    notification["success"]({
        message: message,
        description: descr,
    });
};

interface loggedUser {
    id: number;
    name: string;
    email: string;
    phone: string;
    img: string;
    region: City;
    city: City;
    district: District;
    address: string;
    remember_token?: any;
    role: number;
    taxes: Taxes;
    fees: Fee[];
    isActive: number;
    seller_info_is_open: any;
    isProd:number;
    api_token:string;
    wallet:any;
}

const userJson = JSON.parse(<string>localStorage.getItem("user"));

class AuthStore {
    //
    @observable authToken = localStorage.getItem("token");
    @observable.ref authUser: loggedUser =
        userJson !== null ? userJson : ({} as loggedUser);
    @observable maximumWeight?: IModelMaximumWeight;
    @observable maximumValue?: IModelMaximumValue;

    @observable authRole: any = localStorage.getItem("role");
    @observable pointProfile: any;
    @observable countries: any[] = [];
    @observable walletHistory: WalletHistory[] = [];
    @observable currentWalletValue: number = 0.0;
// Response status
    @observable isLoading: boolean = false;
    @observable isError: boolean = false;
    @observable errors: string = "";
    @observable seller_reg_status = false;
    // Confif
    config = {
        headers: {
            Authorization: `Bearer ${this.authToken}`,
            Accept: "application/json",
        },
    };

    config2 = {
        headers: {
            Authorization: `Bearer ${this.authToken}`,
            "Content-Type": "multipart/form-data",
        },
    };
    // Login
    @action
    actionLogin = (user: any) => {
        this.isLoading = true;
        const hide = message;
        hide.loading("Is Loading");
        this.isError = false;
        axios
            .post("/V1/auth/login", user)
            .then((response) => {
                hide.destroy();
                localStorage.setItem("token", response.data.accessToken);
                localStorage.setItem("role", `${response.data.user.role}`);
                localStorage.setItem("user", JSON.stringify(response.data.user));
                let path = `/`;
                window.location.href = path;
            })
            .catch((err) => {
                hide.destroy();
                this.isLoading = false;
                switch (err.response.status) {
                    case 401:
                        error(i18next.t("LoginFailed.1"));
                        break;
                    case 403:
                        error(i18next.t("LoginFailed.3"));
                        break;
                    case 422:
                        const errors = err.response.data.errors;
                        Object.keys(errors).map((key) => {
                            openNotificationWithIconError(key, `${errors[key][0]}`);
                        });
                        break;
                    default:
                        break;
                }
                this.isError = true;
                this.errors = err.message;
                this.isLoading = false;
            })
            .finally(() => {
                this.isLoading = false;
            });
    };

    @action
    updateLoggedUser = (values: any) => {
        runInAction(() => {
            this.authUser.name = values.name;
            this.authUser.email = values.email;
            this.authUser.phone = values.phone;
            this.authUser.city.id = Number(values.city_id);
            this.authUser.region.id = Number(values.region_id);
            this.authUser.district.id = Number(values.district_id);
            this.authUser.address = values.address;
            localStorage.setItem("user", JSON.stringify(this.authUser));
        });
    };
    @action
    refreshLocalStorage = () => {
        this.authUser = JSON.parse(<string>localStorage.getItem("user"));
    };
    //-------------------------- Register page  ---------------------------------------------
    @action
    addAccount = async (
        values: any,
        setSub: (value: boolean) => void,
        resetForm: () => void,
        route: string,
        _request: number,
        _showNewRegisterModal ?:()=>void
    ) => {
        const hide = message;
        hide.loading("Is Loading");
        axios
            .post(`/V1/${route}`, values, this.config )
            .then((_response) => {
                // @on Success
                hide.destroy();
                this.seller_reg_status = true;
                this.isError = false;
                resetForm();
                message.success(i18next.t("Add.2"))
            })
            .catch((err) => {
                // @On failure
                hide.destroy();
                switch (err.response.status) {
                    case 404:
                        break;
                    case 422:
                        const errors = err.response.data.errors;
                        Object.keys(errors).map((key) => {
                            openNotificationWithIconError(key, `${errors[key][0]}`);
                        });
                        break;
                    default:
                        break;
                }
                this.isError = true;
                return false;
            })
            .finally(() => {
                setSub(false);
            });
    };

    //-------------------------- Register page  ---------------------------------------------
    @action
    addSellerInfo = async (
        values: any,
        setSub: (value: boolean) => void,
        resetForm: () => void,
        route: string,
        request: number
    ) => {
        const hide = message;
        hide.loading("Is Loading");
        axios
            .post(`/V1/${route}`, values, this.config)
            .then((_response) => {
                // @on Success
                switch (request) {
                    case 0:
                        hide.destroy();
                        openNotificationWithIconSuccess(
                            i18next.t("Add.2"),
                            i18next.t("CreateAccount.2")
                        );
                        break;
                    case 1:
                        hide.destroy();
                        openNotificationWithIconSuccess(
                            i18next.t("Add.2"),
                            i18next.t("CreateHub.2")
                        );
                        break;
                }

                this.isError = false;
                resetForm();
            })
            .catch((err) => {
                // @On failure
                hide.destroy();
                switch (err.response.status) {
                    case 404:
                        break;
                    case 422:
                        const errors = err.response.data.errors;
                        Object.keys(errors).map((key) => {
                            openNotificationWithIconError(key, `${errors[key][0]}`);
                        });
                        break;
                    default:
                        break;
                }
                this.isError = true;
                return false;
            })
            .finally(() => {
                setSub(false);
            });
    };

    //-------------------------- Register page  ---------------------------------------------
    @action
    addSellerInformations = async (
        values: any,
        setSub: (value: boolean) => void,
        route: string,
        request: number
    ) => {
        const hide = message;
        hide.loading("Is Loading");
        axios
            .post(`/V1/${route}`, values, this.config2)
            .then((_response) => {
                // @on Success
                switch (request) {
                    case 0:
                        hide.destroy();
                        openNotificationWithIconSuccess(
                            i18next.t("Add.2"),
                            i18next.t("CreateAccount.2")
                        );
                        this.authUser.seller_info_is_open = 0;
                        localStorage.setItem("user", JSON.stringify(authStore));
                        let path = `/`;
                        window.location.href = path;

                        break;
                    case 1:
                        hide.destroy();
                        openNotificationWithIconSuccess(
                            i18next.t("Add.2"),
                            i18next.t("CreateHub.2")
                        );
                        break;
                }

                this.isError = false;
                // resetForm();
            })
            .catch((err) => {
                // @On failure
                hide.destroy();

                switch (err.response.status) {
                    case 401: {
                        const errors = err.response.data.errors;
                        openNotificationWithIconError("Error", `${errors}`);
                    }
                        break;
                    case 404:
                        break;
                    case 422:
                        const errors = err.response.data.errors;
                        if (errors) {
                            Object.keys(errors).map((key) => {
                                openNotificationWithIconError(key, `${errors[key][0]}`);
                            });
                        }

                        break;
                    default:
                        break;
                }
                this.isError = true;
                return false;
            })
            .finally(() => {
                setSub(false);
            });
    };

    @action
    getMax = (route: string, request: number) => {
        axios
            .get(`V1/${route}`, this.config)
            .then((response) => {
                // @on Success
                switch (request) {
                    case 0:
                        this.maximumWeight = response.data[0];
                        break;
                    case 1:
                        this.maximumValue = response.data[0];
                        break;
                }
            })
            .catch((_error) => {
                // @On failure
                this.isError = true;
            })
            .finally(() => {
                this.isLoading = false;
            });
    };

    // updateProfile
    @action
    updateProfile = (_user: any) => {
        axios
            .get("/V1/user/profile", this.config)
            .then((response) => {
                localStorage.setItem("user", JSON.stringify(response.data.user));
            })
            .catch((_err) => {
            })
            .finally(() => {
                this.isLoading = false;
            });
    };

    @action
    getWallet = () => {
        axios
            .get("/V1/user/wallet/history",{
                headers: {
                    Authorization: `Bearer ${this.authToken}`,
                }})
            .then(response => {
                console.log(response.data.data.data)
                this.walletHistory = response.data.data.data;
            })
            .catch(_err => {
                console.log(_err)
            })
    }

    @action
    getCurrentWalletValue = () => {
        axios
            .get("/V1/user/wallet/current-value",{
                headers: {
                    Authorization: `Bearer ${this.authToken}`,
                }})
            .then(response => {
                console.log(response)
                this.currentWalletValue = response.data.data;
                return  this.currentWalletValue;
            })
            .catch(_err => {
                console.log(_err)
            })
    }

    @action
    getPointProfile = () => {
        axios.get("/V1/point/profile", this.config).then((response) => {
            this.pointProfile = (response.data)
        }).catch((_err) => {
            return null
        })
    }

    @action
    updatePointProfile = (values:any ) => {
        axios.post("/V1/point/update",values, this.config).then((response) => {
            openNotificationWithIconSuccess(
                i18next.t("update_profile"),
                ""
            );
            this.pointProfile = (response.data.data)

        }).catch((_err) => {
            error(i18next.t("Error.1"));

        })
    }

    @action
    getCountries = () => {
        axios
            .get("/V1/nationality/show")
            .then(response => {
                this.countries = (response.data);
            })
            .catch(_err => {
            })
    }
}

export const authStore = new AuthStore();
const AuthStoreContext = createContext(new AuthStore());
export default AuthStoreContext;
