import React, {useState, useEffect} from 'react';
import "../style/overview.scss"
import {DownloadOutlined} from '@ant-design/icons';
import {Input, DatePicker, Table, Tag,Button, Radio, ConfigProvider} from 'antd';
import { RangePickerPrimary } from "../../global-styled-components/Inputs";
import {CSVLink} from "react-csv";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import axios from "../../../axios";
import {v4 as uuid} from "uuid";
import dayjs from "dayjs";
import { isEmpty } from "lodash";

const Report = () => {
    const {Search} = Input;
    const {t} = useTranslation();
    let [selectedRowKeys, setSelectedRowKeys] = useState([]);
    let [loading, setLoading] = useState(false);
    let [dataSelected, setDataSelected] = useState([]);
    let [orders, setOrders] = useState([]);
    let [dates, setDates] = useState([]);
    const [searchParams, setSearchParams] = useState("");
    let isError = false;

    let arr = [];
    const dateFormat = "YYYY-MM-DD";
    let onDatesChange = (date, dateString) => {
        if (!isEmpty(date)) {
          arr.push(new Date(dayjs(date[0]).format(dateFormat)));
          arr.push(new Date(dayjs(date[1]).format(dateFormat)));
          setDates(arr);
        } else setDates([]);
      };

    // Handling Api request
    const getPointsOrders = async (value,col) => {
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: "application/json",
            },
            params: {
                search: value,
                dateFrom: arr[0],
                dateTo: arr[1],
                status: col
            }
        };
        
        axios.get("/V1/point/orders", config)
            .then((response) => {
                setOrders(response.data.data);
            })
            .catch((error) => {
                console.log("AXIOS ERROR in getPointStatics: ", error);
                isError = true;
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        getPointsOrders();
    }, []);

    let property = ['trackingId', 'customerName', 'phone', 'paymentStatus', 'cost', 'point']
    const onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
        setDataSelected(orders.filter(
            s1 => selectedRowKeys.some(
                s2 => s1.key === s2)).map(
                s => (property.reduce(
                    (newS, data1) => {
                        newS[data1] = s[data1];
                        return newS;
                    }, {})
                )
            )
        );
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
        {title: t("TrackingId.1"), dataIndex: 'tracking_id',},
        {
            title: t("Customer_name.1"), dataIndex: 'receiver', render: reciver => {
                return <span>{reciver.name}</span>
            }
        },

        {
            title: t("Phone_number.1"), dataIndex: 'receiver', render: reciver => {
                return <span dir="ltr">+966 {reciver.phone}</span>
            }
        },
        {
            title: t("PaymentStatus.1"), dataIndex: 'payment_status',
            render: payment_status => {
                return (
                    <span>
          {payment_status === "unpaid" ? (
              <Tag color="orange" key={uuid()}>
                  {payment_status}
              </Tag>
          ) : (
              <Tag color="green" key={uuid()}>
                  {payment_status}
              </Tag>
          )}
        </span>
                );
            }
        },
        {title: t("Cost.1"), dataIndex: 'cod_amount'},
        {
            title: i18next.t("Point.1"), dataIndex: 'hold_by', render: point => {
                return <span>{point.name}</span>
            }
        },
    ];

    return (
        <div>
            <ConfigProvider direction={i18next.dir()}>
                <div className="">
                    <ul className="flex-container space-between mg-top-5px">
                        <li>
                            <label>
                                <strong className="font-20px">{t("Report.1")}</strong>
                            </label>
                        </li>

                        <li>
                    <span className="tx-aln-end">
                        <ul className="flex-container space-between">
                            <li className="mg-lr-5px">
                                <Search
                                    placeholder="Search by name or Tracking ID"
                                    onSearch={value => {
                                        getPointsOrders(value,"");
                                    }}
                                />
                            </li>
                            <li className="mg-lr-5px">
                                <RangePickerPrimary
                                    onChange={value => {
                                        onDatesChange(value);
                                        getPointsOrders();
                                    }}
                                    format={dateFormat}
                                />
                            </li>
                            <li className="mg-lr-5px">
                                <Radio.Group>
                                    <Radio.Button value="All" onClick={() => {getPointsOrders("","all")}}>{t("All.1")}</Radio.Button>
                                    <Radio.Button value="Collected" onClick={() => {getPointsOrders("","collected")}}>{t("Collected.2")}</Radio.Button>
                                    <Radio.Button value="Released" onClick={() => {getPointsOrders("","released")}}>{t("PointStatus.released")}</Radio.Button>
                                </Radio.Group>
                            </li>
                            <li>
                                {hasSelected ?
                                    <CSVLink data={dataSelected} filename={"NoulReport.csv"} target="_blank">
                                        <Button className="img-btn mg-lr-5px" icon={<DownloadOutlined/>}></Button>
                                    </CSVLink>
                                    : <Button className="img-btn mg-lr-5px" icon={<DownloadOutlined/>}></Button>
                                }
                            </li>
                        </ul>
                    </span>
                        </li>
                    </ul>
                    <br/>
                </div>

                <div style={{marginBottom: 8}}>
                    <Table loading={loading} rowSelection={rowSelection} columns={columns} dataSource={orders?.reverse()}/>
                </div>
            </ConfigProvider>
        </div>
    );
};

export default Report;