// @flow
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import { ConfigProvider, Layout, Menu} from "antd";
import {BrowserRouter, NavLink as Link, Route, Switch, useLocation,} from "react-router-dom";
import {
    AppstoreOutlined
} from "@ant-design/icons";
import styled from "styled-components";
import logoSmile from "../../assets/noul-smile-logo.svg";
import ProfileLayout from "../Courier-Dashboard/profile-layout/profile-layout";
import {colors} from "../../styles/global";
import i18next from "../../i18next";
import Overview from "./overview/Overview";
import Points from "./points/Points";
import {StoreMallDirectory} from "@styled-icons/material-outlined/StoreMallDirectory"
 import PointLayout from "./points/point/point-layout/point-layout";
import PointAdminMenuBar from "../menu-bar.component/point-admin-menu-bar.component";
const {  Sider } = Layout;

type Props = {};
const PointIndex = (_props: Props) => {
  const location = useLocation();
  let [collapsed, setCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [width] = useState(window.innerWidth);

  const { t } = useTranslation();
  let [selected, setSelected] = useState([location.pathname]);

  const handelCollapse = () => {
    setCollapsed(!collapsed);
  };
  useEffect(() => {
    if (width < 768 && !isMobile) {
      setIsMobile(true);
    } else if (isMobile) {
      setIsMobile(false);
    }
  }, [width]);

  const StyeledMenu = styled(Menu)`
    background: #29243c;
    height: 100%;
    padding: ${collapsed ? "0em" : "1em"};
    .menu-tag {
      color: white;
      margin: 2em 1em;
      cursor: default;
      font-weight: bold;
      display: block;
      opacity: 0.6;
    }
  `;

  const StyeledMenuItem = styled(Menu.Item)`
    border-radius: ${collapsed ? "0" : "28px"};
  `;



  return (
    <BrowserRouter>
      <Layout>
        <StyeldSider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={270}
          style={{ minHeight: "100vh" }}
        >
          <div className="logo">
            <a href="https://noul.net/">
              <img className="smile-menu-header" src={logoSmile} />
            </a>
          </div>

          <ConfigProvider direction={i18next.dir()}>
            <StyeledMenu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={["/"]}
              selectedKeys={selected}
              onSelect={(selectParam: any) => {
                setSelected(selectParam.keyPath);
              }}
            >
              {collapsed ? null : (
                <span className="menu-tag">{t("Menu.1")}</span>
              )}
              <StyeledMenuItem key="/">
                <Link to="/">
                  <AppstoreOutlined />
                  <span>{t("Overview.1")}</span>
                </Link>
              </StyeledMenuItem>

              <StyeledMenuItem key="/points">
                <Link to="/points">
                  <StoreMallDirectoryStyled size={"16"}/>
                  <span>{t("Point.1")}</span>
                </Link>
              </StyeledMenuItem>
            </StyeledMenu>
          </ConfigProvider>
        </StyeldSider>
        <Layout className="site-layout">
          <PointAdminMenuBar handelCollapse={handelCollapse} collapsed={collapsed} isMobile={isMobile}/>

          <Switch>
            <Route
              path="/profile"
              component={() => (
                <ProfileLayout modalCollapse={() => console.log("updated")} />
              )}
            />
            {/*@ts-ignore*/}
            <Route path="/points" exact={true} component={() => <Points endpoint={"/V1/point/users"} />} />

            <Route path="/points/:point_id" exact={true} component={() => <PointLayout endpoint={"/V1/point/users"}/>} />


            <Route
              exact={true}
              path="/"
              component={() => <Overview />}
            />
          </Switch>
        </Layout>
      </Layout>
    </BrowserRouter>
  );
};

const StyeldSider = styled(Sider)`
  // background: ${colors.color_primary_dark};
  background: #373050;
  
  .smile-menu-header{
  margin: 30px .5em;
  padding: 1.8em 1em;
  width: 65px;
  background-color: ${colors.color_primary};
  border-radius: 10px;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub{
background: transparent !important;
}
`;
const StoreMallDirectoryStyled = styled(StoreMallDirectory)`
  margin-inline-end: 0.7em;
`;
export default PointIndex;