import React, { FC, useState } from "react";
import { Formik } from "formik";
import { Alert, Col, Form, Row } from "antd";
import { MyLabel } from "../../global-styled-components/Labels";
import { PasswordBorderedPrimaryInput } from "../../global-styled-components/Inputs";
import { PrimaryButton } from "../../global-styled-components/Buttons";
import { useTranslation } from "react-i18next";
import ProfileWrapper from "../../global-styled-components/ProfileWrapper";
import { observer } from "mobx-react-lite";
import axios from "../../../axios";
import * as Yup from "yup";
import { authStore } from "../../../stores/AuthStore";
import { AnimatePresence, motion } from "framer-motion";
import i18next from "i18next";

type Props = {
  modalCollapse: any;
};
const ResetLoggedPassword: FC<Props> = observer(({ modalCollapse }: Props) => {
  const { t } = useTranslation();
  let [error, setError] = useState("");

  // User @object
  const userData = {
    old_password: "",
    password_confirmation: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    old_password: Yup.string()
      .min(8, t("PasswordValidate.1"))
      .max(64)
      .required(t("InputRequired.1")),
    password: Yup.string()
      .min(8, t("PasswordValidate.1"))
      .max(64)
      .required(t("InputRequired.1")),
    password_confirmation: Yup.string()
      .min(8, t("PasswordValidate.1"))
      .max(64)
      .required(t("InputRequired.1"))
      .oneOf([Yup.ref("password")], t("PasswordMatch.1")),
  });
  return (
    <ProfileWrapper>
      <Formik
        initialValues={userData}
        validationSchema={validationSchema}
        onSubmit={(values, formikHelpers) => {
          axios
            .post(
              `https://logistech-be.coreplexs.com/V1/user/password/reset`,
              values,
              authStore.config
            )
            .then((_response) => {
              // response.data;
              //TODO refresh_token_for_logged343 
              modalCollapse();
            })
            .catch((error) => {
              // @On failure
              switch (error.response.status) {
                case 401:
                  setError(i18next.t("LoginFailed.2"));
                  break;
                default:
                  setError(error.response.message);
                  break;
              }
            })
            .finally(() => {
              formikHelpers.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form>
            <Row gutter={[20, 16]}>
              <Col md={24} sm={24} xs={24}>
                <Row className="layout-element" gutter={16}>
                  <Col xs={24} style={{ marginBottom: "1em" }}>
                    <AnimatePresence>
                      {error !== "" ? (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 0.6, ease: "easeInOut" }}
                        >
                          <Alert
                            message={error}
                            type="error"
                            onClose={() => setError("")}
                          />
                        </motion.div>
                      ) : null}
                    </AnimatePresence>
                  </Col>
                  <Col md={24} sm={24}>
                    <Form.Item>
                      <MyLabel>{t("Password.1")}</MyLabel>
                      <PasswordBorderedPrimaryInput
                        placeholder="••••••••"
                        type="password"
                        name="old_password"
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.old_password}
                        style={{ marginBottom: 0 }}
                      />

                      {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
                      {touched.old_password && errors.old_password ? (
                        <div className="error-message">
                          {errors.old_password}
                        </div>
                      ) : null}
                    </Form.Item>
                  </Col>
                  <Col md={24} sm={24}>
                    <Form.Item>
                      <MyLabel>{t("Password.3")}</MyLabel>
                      <PasswordBorderedPrimaryInput
                        placeholder="••••••••"
                        type="password"
                        name="password"
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        style={{ marginBottom: 0 }}
                      />

                      {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
                      {touched.password && errors.password ? (
                        <div className="error-message">{errors.password}</div>
                      ) : null}
                    </Form.Item>
                  </Col>
                  <Col md={24} sm={24}>
                    <Form.Item>
                      <MyLabel>{t("Password.2")}</MyLabel>
                      <PasswordBorderedPrimaryInput
                        placeholder="••••••••"
                        type="password"
                        name="password_confirmation"
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password_confirmation}
                        style={{ marginBottom: 0 }}
                      />

                      {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
                      {touched.password_confirmation &&
                      errors.password_confirmation ? (
                        <div className="error-message">
                          {errors.password_confirmation}
                        </div>
                      ) : null}
                    </Form.Item>
                  </Col>

                  {/*5- City Input*/}
                  <Col xs={12}>
                    <PrimaryButton
                      htmlType="submit"
                      onClick={() => handleSubmit()}
                    >
                      {t("ResetPassword.2")}
                    </PrimaryButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </ProfileWrapper>
  );
});
export default ResetLoggedPassword;
