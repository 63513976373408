import * as Yup from "yup";
import i18next from "i18next";
const phoneRegExp = new RegExp(/(5)[0-9]{8}/);

export const PointInfoSchema = Yup.object().shape({
  name: Yup.string()
    .max(128, "الاسم لايتجاوز 128 خانه").required(i18next.t("InputRequired.1")),
  email: Yup.string()
    .max(64).required(i18next.t("InputRequired.1")),
  phone: Yup.string()
      .matches(phoneRegExp, i18next.t("PhoneRegValidation.1"))
      .required(i18next.t("InputRequired.1")),
  point_cost_per_order: Yup.number().required(i18next.t("InputRequired.1")),
  point_capacity: Yup.number().required(i18next.t("InputRequired.1")),
  location:Yup.object().shape({
    title: Yup.string()
        .max(128, "الاسم لايتجاوز 128 خانه").required(i18next.t("InputRequired.1")),
    address: Yup.string()
        .max(128, "العنوان لايتجاوز 128 خانه").required(i18next.t("InputRequired.1")),
    comment: Yup.string()
        .max(128, "التعليق لايتجاوز 128 خانه").required(i18next.t("InputRequired.1")),
    map_link: Yup.string().max(1000).nullable(),
    latitude: Yup.string().nullable(),
    longitude: Yup.string().nullable(),

  })

});
