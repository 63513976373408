import axios from "axios";
import {apiUrl} from "../axios";

const HttpClient = axios.create({
    baseURL:`${apiUrl}/V1/`,
    // timeout: 1000,
});

HttpClient.interceptors.request.use(function (config) {
    config.headers = {
        ...config.headers,
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };
    // you can also do other modification in config
    return config;
}, function (error) {
    return Promise.reject(error);
});

HttpClient.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    switch (error.response.status) {
        case 500:
            return Promise.reject(error.response);

        case 403:
            return Promise.reject(error.response);

        case 400:
            return Promise.reject(error.response.data.detail);

        default:
            return Promise.reject(error);
    }
});

export default HttpClient;