import React, {useState, useEffect} from 'react';
import axios from "../../../../../axios"
import { RangePickerPrimary } from '../../../../global-styled-components/Inputs';
import { Input, DatePicker, Button, Radio, Table, Tag } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import {v4 as uuid} from "uuid";

const PointReport = (point_id) => {
    const { Search } = Input;
    const {id} = point_id;
    const {t} = useTranslation();

    let [selectedRowKeys,setSelectedRowKeys] = useState([]);
    let [dataSelected,setDataSelected] = useState([]);
    let [loading,setLoading] = useState(false);
    let [orders, setOrders] = useState([]);
    const [searchParams, setSearchParams] = useState("");
    let [dates, setDates] = useState([]);
    let isError = false;

    const dateFormat = "YYYY-MM-DD";
    let arr = [];
    let onDatesChange = (date) => {
        if (!isEmpty(date)) {
          arr.push((dayjs(date[0]).format(dateFormat)));
          arr.push((dayjs(date[1]).format(dateFormat)));
          setDates(arr);
        } else setDates([]);
    };

    const getPointsOrders = async (value,col) => {
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: "application/json",
            },
            params: {
                search: value,
                dateFrom: arr[0],
                dateTo: arr[1],
                status: col
            }
        };

        axios.get(`/V1/point/users/${id}/orders`, config)
            .then((response) => {
                setOrders(response.data.data);
            })
            .catch((error) => {
                console.log("AXIOS ERROR in get report 1 point: ", error);
                isError = true;
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        getPointsOrders();
    }, []);

    let property = ['trackingId', 'customerName','phone', 'paymentStatus', 'cost','point']
    const onSelectChange = selectedRowKeys => {
        setSelectedRowKeys(selectedRowKeys);
        setDataSelected(orders.filter(
            s1 => selectedRowKeys.some(
                s2 => s1.key === s2)).map(
                s => (property.reduce(
                    (newS, data1) => {
                        newS[data1] = s[data1];
                        return newS;
                    }, {})
                )
            )
        );
    };
    
    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    
    return (
        <div>
            <div className="">
                <ul className="flex-container space-between mg-top-5px" >
                    <li>
                        <label>
                            <strong className="font-20px"></strong>
                        </label>
                    </li> 

                    <li>  
                    <span className="tx-aln-end">
                        <ul className="flex-container space-between">
                            <li className="mg-lr-5px">
                                <Search
                                    placeholder="Search by name or Tracking ID"
                                    onSearch={value => {
                                        getPointsOrders(value,"")
                                    }}
                                />
                            </li>
                            <li className="mg-lr-5px">
                                <RangePickerPrimary
                                    onChange={value => {
                                        onDatesChange(value);
                                        getPointsOrders();
                                    }}
                                    format={dateFormat}
                                />
                            </li>
                            <li className="mg-lr-5px">
                                <Radio.Group>
                                    <Radio.Button value="All" onClick={() => {getPointsOrders("","all")}}>{t("All.1")}</Radio.Button>
                                    <Radio.Button value="Collected" onClick={() => {getPointsOrders("","collected")}}>{t("Collected.2")}</Radio.Button>
                                    <Radio.Button value="Released" onClick={() => {getPointsOrders("","released")}}>{t("PointStatus.released")}</Radio.Button>
                                </Radio.Group>
                            </li>
                            <li>
                                { hasSelected?
                                <CSVLink data={dataSelected} filename={"NoulReport.csv"} target="_blank">
                                    <Button className="img-btn mg-lr-5px" icon={<DownloadOutlined/>}></Button>
                                </CSVLink>
                                : <Button className="img-btn mg-lr-5px" icon={<DownloadOutlined/>}></Button>
                                }
                            </li>
                        </ul>
                    </span>
                    </li>
                </ul>
                <br/>
            </div>

            <div style={{ marginBottom: 8 }}>
                <Table rowSelection={rowSelection} columns={columns} dataSource={orders} />
            </div>
        </div>
    );
};
const columns = [
    {title: 'Tracking ID', dataIndex: 'tracking_id',},
    {
        title: 'Customer name', dataIndex: 'receiver', render: reciver => {
            return <span>{reciver.name}</span>
        }
    },

    {
        title: 'Phone', dataIndex: 'receiver', render: reciver => {
            return <span dir="ltr">+966 {reciver.phone}</span>
        }
    },
    {
        title: 'Payment Status', dataIndex: 'payment_status',
        render: payment_status => {
            return (
                <span>
          {payment_status === "unpaid" ? (
              <Tag color="orange" key={uuid()}>
                  {payment_status}
              </Tag>
          ) : (
              <Tag color="green" key={uuid()}>
                  {payment_status}
              </Tag>
          )}
        </span>
            );
        }
    },
    {title: 'Cost', dataIndex: 'cod_amount'},
    {
        title: 'Point', dataIndex: 'hold_by', render: point => {
            return <span>{point.name}</span>
        }
    },
];

export default PointReport;