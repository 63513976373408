import React, {FunctionComponent, useState} from 'react';
import {Col, Row} from "antd";
import dropIcon from "../../../assets/ionic-ios-arrow-dropright.svg";
import styled from "styled-components";
import dayjs from "dayjs";
import OrderDetails from "./order-details/order-details";
import {MyCheckBox} from "../../global-styled-components/Inputs";
import {useTranslation} from "react-i18next";

interface OwnProps {
    item:any,
    removeSelectedOrder:any,
    addSelectedOrder:any
}

type Props = OwnProps;

const OrderCard: FunctionComponent<Props> = (props) => {

    let [opened,setOpened]  =useState(false)
    const {t} = useTranslation();
  return (
      <>
          <StyledLocationButton xs={24} style={{margin:'0.5em 0'}}>
              <Row>
                  {/*{"id":6485,"tracking":"S6485318957","seller_name":"Hashim","cod_amount":"1.00","payment_status":"unpaid","created_at":"2020-12-02 20:58:36"}*/}

                  <Col xs={24} onClick={()=>setOpened(!opened)}>
                      <h2 className="text-color-primary">{props.item.seller.name}</h2>
                  </Col>

                  <Col xs={4} className='aln-center'>
                      <MyCheckBox defaultChecked className='centered-v' onChange={(e)=>{if(e.target.checked){
                          props.addSelectedOrder(props.item.id)
                      }else props.removeSelectedOrder(props.item.id)}}/>
                  </Col>
                  <Col xs={8} onClick={()=>setOpened(!opened)}>
                      <h4 className={"text-color-dark"}>{t("TrackingId.1")}</h4>
                      <h5 className={"text-color-primary"} >{props.item.tracking}</h5>

                  </Col>

                  <Col xs={8}>
                      <h4 className={"text-color-dark"}>{t("OrderDetails.2")}</h4>
                      <h5 className={"text-color-primary"} style={{textAlign:'start'}} >{dayjs(props.item.created_at).format('DD/MM/YYYY hh:mm A')}
                      </h5>
                  </Col>

                  <Col className='aln-center' xs={4} onClick={()=>setOpened(!opened)}>
                      <img src={dropIcon} />
                  </Col>
                  {
                      opened &&  <Col xs={24}>
                          <OrderDetails tracking_history={props.item.tracking_history}/>
                      </Col>
                  }


              </Row>
          </StyledLocationButton>
      </>
  );
};
const StyledLocationButton = styled(Col)`
background: white;
padding: 1.3em;
border-radius: 10px ;
cursor: pointer;
h4{
margin-top: .7em;
font-weight: 600;
}
`;
export default OrderCard;
