import React, { FC, useContext, useEffect, useState } from "react";
import { Formik, isEmptyArray } from "formik";
import { ProfileSchema } from "../../../validations/ProfileSchema";
import {
  Col,
  Form,
  Row,
  Select as $Select,
  Skeleton,
  Alert,
} from "antd";
import { MyLabel, MyLabelMarg } from "../../global-styled-components/Labels";
import { ProfileInput } from "../../global-styled-components/Inputs";
import { PrimaryButton } from "../../global-styled-components/Buttons";
import { useTranslation } from "react-i18next";
import IModelProfile from "../../../models/Profile";
import ProfileWrapper from "../../global-styled-components/ProfileWrapper";
import { observer } from "mobx-react-lite";
import AuthStoreContext from "../../../stores/AuthStore";
import { Select } from "formik-antd";
import RegisterStoreContext from "../../Register/registerStore";
import i18next from "i18next";
import City from "../../../models/City";
import District from "../../../models/District";
import axios from "../../../axios";
import { AnimatePresence, motion } from "framer-motion";

type Props = {
  modalCollapse: any;
};
const Profile: FC<Props> = observer(({ modalCollapse }: Props) => {
  const { t } = useTranslation();
  // User @object
  const authStore = useContext(AuthStoreContext);
  const registerStore = useContext(RegisterStoreContext);
  const [cityLoading, setCityLoading] = useState(false);
  const [districtLoading, setDistrictLoading] = useState(false);
  const [regions, setRegions] = useState<City[]>(registerStore.region);
  const [cities, setCities] = useState<City[]>(registerStore.cities);
  const [districs, setDistrics] = useState<District[]>(registerStore.districs);
  let [error, setError] = useState("");

  useEffect(() => {
    if (isEmptyArray(regions)) {
      registerStore.getRegion(setRegions);
      registerStore.getCity(setCities);
      registerStore.getDistrict(setDistrics);
    }
  });

  const account = new IModelProfile();
  account.name = authStore.authUser.name;
  account.email = authStore.authUser.email;
  account.phone = authStore?.authUser?.phone?.toString();
  account.city_id = authStore.authUser?.city?.id.toString();
  account.region_id = authStore.authUser?.region?.id.toString();
  account.district_id = authStore.authUser?.district?.id.toString();
  account.address = authStore.authUser?.address;
  account.app_key = authStore.authToken!!


  return (
    <ProfileWrapper>
      <Formik
        initialValues={account}
        validationSchema={ProfileSchema}
        onSubmit={(values, formikHelpers) => {
          axios
            .post(
              `V1/user/profile`,
              values,
              authStore.config
            )
            .then((_response) => {
              // response.data;
              authStore.updateLoggedUser(values);
              modalCollapse();
            })
            .catch((error) => {
              try {
                let err = error.response.status;
                switch (err) {
                  case 400:
                    setError(err);
                    break;
                  case 422:
                    setError(err);
                    break;
                  case 403:
                    setError(err);
                    break;
                  case 401:
                    setError(err);
                    break;
                  case 404:
                    setError(err);
                    break;
                }
              } catch (e) {
                setError(e.toString());
              }
            })
            .finally(() => {
              formikHelpers.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form>
            {/*1- Name Input*/}
            <Row gutter={[20, 2]}>
              <Col md={24} sm={24} xs={24}>
                <Row className="layout-element" gutter={16}>
                  <Col xs={24} style={{ marginBottom: "1em" }}>
                    <AnimatePresence>
                      {error !== "" ? (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 0.6, ease: "easeInOut" }}
                        >
                          <Alert message={error} type="error" />
                        </motion.div>
                      ) : null}
                    </AnimatePresence>
                  </Col>

                  <Col md={12} sm={24}>
                    <Form.Item>
                      <MyLabel>{t("Name.1")}</MyLabel>
                      <ProfileInput
                        placeholder="John Doe"
                        type="name"
                        name="name"
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />

                      {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
                      {touched.name && errors.name ? (
                        <div className="error-message">{errors.name}</div>
                      ) : null}
                    </Form.Item>
                  </Col>

                  <Col md={12} xs={24}>
                    {/* --------------------------------------- --- ----------- */}
                    {/*2- Email Input*/}
                    <Form.Item>
                      <MyLabel>{t("Email.1")}</MyLabel>
                      <ProfileInput
                        disabled
                        placeholder="email@example.com"
                        type="email"
                        name="email"
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />

                      {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
                      {touched.email && errors.email ? (
                        <div className="error-message">{errors.email}</div>
                      ) : null}
                    </Form.Item>
                  </Col>

                  {/*8- Phone Input*/}

                  <Col xs={24}>
                    <Form.Item>
                      <MyLabel>Phone Number</MyLabel>
                      <ProfileInput
                        placeholder={t("Phone_number.1")}
                        type="text"
                        name="phone"
                        autoComplete="on"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                      />

                      {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
                      {touched.phone && errors.phone ? (
                        <div className="error-message">{errors.phone}</div>
                      ) : null}
                    </Form.Item>
                  </Col>

                  {/*5- Region Input*/}

                  {!isEmptyArray(regions) && (
                    <Col xs={12}>
                      <Form.Item>
                        <MyLabelMarg>{t("Region.1")}</MyLabelMarg>

                        <Select
                          showSearch
                          // loading={registerStore.isLoading}
                          name="region_id"
                          className="login-input"
                          size="large"
                          style={{ width: "100%" }}
                          value={values.region_id}
                          optionFilterProp="children"
                          filterOption={(inputValue, option) =>
                            option!!.props.children
                              .toString()
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                          onChange={() => {
                            if (registerStore.cities.length === 0) {
                              registerStore.getCity();
                              setCityLoading(true);
                            }
                          }}
                        >
                          <$Select value="" disabled>
                            {t("Region.1")}
                          </$Select>
                          {regions.map((item) => {
                            return (
                              <$Select key={item.id} value={item.id.toString()}>
                                {i18next.dir() === "rtl"
                                  ? item.name_ar
                                  : item.name_en}
                              </$Select>
                            );
                          })}
                        </Select>

                        {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
                        {touched.region_id && errors.region_id ? (
                          <div className="error-message">
                            {errors.region_id}
                          </div>
                        ) : null}
                      </Form.Item>
                    </Col>
                  )}

                  {/*6- City Input*/}
                  <Col xs={12}>
                    <Form.Item>
                      <MyLabelMarg>{t("City.1")}</MyLabelMarg>

                      <Select
                        showSearch
                        loading={cityLoading}
                        size="large"
                        name="city_id"
                        value={values.city_id}
                        className="login-input"
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(inputValue, option) =>
                          option!!.props.children
                            .toString()
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        disabled={values.region_id === "" ? true : false}
                      >
                        <$Select value="" disabled>
                          {t("City.1")}
                        </$Select>
                        {cities.map((item) => {
                          // if (item.isActive === 1) {
                          if (cityLoading) {
                            setCityLoading(false);
                          }
                          return (
                            <$Select key={item.id} value={item.id.toString()}>
                              {i18next.dir() === "rtl"
                                ? item.name_ar
                                : item.name_en}
                            </$Select>
                          );
                        })}
                      </Select>

                      {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
                      {touched.city_id && errors.city_id ? (
                        <div className="error-message">{errors.city_id}</div>
                      ) : null}
                    </Form.Item>
                  </Col>
                  {/* --------------------------------------- --- ----------- */}
                  {/*7- District*/}
                  <Col xs={12}>
                    <Form.Item>
                      <MyLabelMarg>{t("District.1")}</MyLabelMarg>

                      <Skeleton
                        loading={isEmptyArray(districs)}
                        active={true}
                        paragraph={false}
                      >
                        <Select
                          showSearch
                          loading={districtLoading}
                          size="large"
                          name="district_id"
                          value={values.district_id?.toString()}
                          className="login-input"
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          filterOption={(inputValue, option) =>
                            option!!.props.children
                              .toString()
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                          disabled={values.city_id === "" ? true : false}
                        >
                          <$Select value="" disabled>
                            {t("District.1")}
                          </$Select>
                          {districs.map((item) => {
                            // if (item.isActive === 1) {
                            if (districtLoading) {
                              setDistrictLoading(false);
                            }
                            if (values.city_id === item.city_id.toString()) {
                              return (
                                <$Select
                                  key={item.id}
                                  value={item.id.toString()}
                                >
                                  {" "}
                                  {i18next.dir() === "rtl"
                                    ? item.name_ar
                                    : item.name_en}
                                </$Select>
                              );
                            }
                            // }
                          })}
                        </Select>
                      </Skeleton>

                      {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
                      {touched.district_id && errors.district_id ? (
                        <div className="error-message">
                          {errors.district_id}
                        </div>
                      ) : null}
                    </Form.Item>
                  </Col>
                  {/* --------------------------------------- --- ----------- */}

                  {/*8- address*/}
                  <Col md={12} sm={24}>
                    <Form.Item>
                      <MyLabel>{t("Address.1")}</MyLabel>
                      <ProfileInput
                        placeholder={t("Address.1")}
                        type="name"
                        name="name"
                        className="login-input"
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                      />

                      {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
                      {touched.name && errors.name ? (
                        <div className="error-message">{errors.name}</div>
                      ) : null}
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item>
                      <MyLabel>{t("Api_key")}</MyLabel>
                      <ProfileInput
                          placeholder={t("Address.1")}
                          type="name"
                          disabled={true}
                          className="login-input"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={authStore.authUser!!.api_token}
                      />
                    </Form.Item>
                  </Col>

                  {/*5- City Input*/}
                  <Col xs={12}>
                    <PrimaryButton
                      htmlType="submit"
                      onClick={() => handleSubmit()}
                    >
                      {t("update") + " " + t("Profile.1")}
                    </PrimaryButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </ProfileWrapper>
  );
});
export default Profile;
