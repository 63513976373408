import React, {useContext, useEffect, useState} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import "./App.scss";
import Protectedroute from "./routes/Protectedroute";
import Login from "./components/Login/Login";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import RegisterLayout from "./components/Register/register-layout";
import {observer} from "mobx-react-lite";
import LanguageStoreContext from "./stores/languageStore";
import ForgetPassword from "./components/ForgetPassword/ForgetPassword";
import NewPassword from "./components/ForgetPassword/NewPassword";
import ClientIndex from "./components/Client-Dashboard/client-index";

const App = observer(() => {
  // hook used to get the translations
  const { t } = useTranslation();
  // direct is to specify the direction of the app RTL -- LTY
  const [direct] = useState(t("ActiveLanguage.dir"));
  // mobx store that handle the application languages and direction
  const languageStore = useContext(LanguageStoreContext);

  // on first load set the language from languageStore.activeLaguage
  useEffect(() => {
    i18next.changeLanguage(languageStore.activeLaguage);
  }, []);

  return (
    <div className="App" dir={t("ActiveLanguage.dir")}>
      <header className="App-header"></header>
      <BrowserRouter>

        <Switch>

          {/*Public for all client*/}
          <Route path="/login" exact={true} component={Login} />
          <Route path="/client" exact={true} component={ClientIndex} />
          <Route path="/register" exact={true} component={RegisterLayout} />
          <Route path="/reset" component={ForgetPassword} />
          <Route
            path="/password/forgetPassword"
            exact={true}
            render={() => <NewPassword />}
          />

          {/*<Route*/}
          {/*    path="/"*/}
          {/*    exact={true}*/}
          {/*    render={({ match }) => <PointIndex />}*/}
          {/*/>*/}

          {/*Need to be auth*/}
          <Protectedroute path="/" exact={false} dirct={direct} />
        </Switch>
      </BrowserRouter>
    </div>
  );
});

export default App;
