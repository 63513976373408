import React, {useContext, useEffect, useState} from 'react';
import {Formik} from "formik";
import {Form, message, Row, Col, ConfigProvider} from "antd";
import PointAddressForm from "./point-address-form";
import {GhostButton, PrimaryButton} from "../../../global-styled-components/Buttons";
import {PointAddressSchema} from "../../../../validations/PointAddressSchema";
import {PointInfoSchema} from "../../../../validations/PointInfoSchema";
import LocationFields from "../../../Admin-Dashboard/add-account/new-account-form/account-fields/location-fields";
import PointsAccountFields
    from "../../../Admin-Dashboard/add-account/new-account-form/account-fields/points-account-fields";
import {PointAccountSchema} from "../../../../validations/PointAccountSchema";
import {observer} from "mobx-react-lite";
import RegisterStoreContext from "../../../Register/registerStore";
import {useTranslation} from "react-i18next";
import AuthStoreContxt from "../../../../stores/AuthStore";
import axios from "../../../../axios"
import AuthStoreContext from "../../../../stores/AuthStore";

const PointFormLayout = observer((props) => {

    let [activeForm, setActiveForm] = useState(false);
    let [activeSchema, setActiveSchema] = useState(0);
    const registerStore = useContext(RegisterStoreContext);
    const authStore = useContext(AuthStoreContext);
    let [point,setPoint] = useState()

    const {t} = useTranslation()

    useEffect(() => {
        registerStore.getRegion();
    },[])

      useEffect(() => {
        authStore.getPointProfile();
    },[])

    let pointUser = {

        name: "",
        email: "",
        title: "",
        address: "",
        comment: "",
        map_link: "",
        latitude: "",
        longitude: "",
        cr_file: null,
        password: "",
        password_confirmation: "",
        city_id: "",
        region_id: "",
        district_id: "",
        phone: "",
        role_id: 6,
        isActive: "",
        point_id: authStore.pointProfile?.id,
        point_capacity: undefined

    }

    const addressSchema = PointAddressSchema;
    const infoSchema = PointInfoSchema;
    const schema = [infoSchema, addressSchema]

    const refreshForm = () => {
        setActiveForm(false);
        setActiveSchema(0);
    }
    return (
        <div>
            <Formik
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    values.point_id = authStore.pointProfile.id
                    authStore.addAccount(
                        values,
                        setSubmitting,
                        resetForm,
                        "auth/register",
                        0
                    );
                }}
                initialValues={pointUser}
                validationSchema={PointAccountSchema}
                render={({
                             handleBlur,
                             handleChange,
                             handleSubmit,
                             setFieldValue,
                             values,
                             errors,
                             touched,

                         }) => (
                    <Form><Row>

                        {
                            !activeForm ? <>
                                    <PointsAccountFields
                                    handleChange={handleChange}
                                    values={values}
                                    handleBlur={handleBlur}
                                    errors={errors}
                                    touched={touched}
                                /> <LocationFields
                                        handleChange={handleChange}
                                        values={values}
                                        handleBlur={handleBlur}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </> :
                                <PointAddressForm handleBlur={handleBlur}
                                                  handleChange={handleChange}
                                                  setFieldValue={setFieldValue}
                                                  values={values}
                                                  errors={errors}
                                                  touched={touched}/>
                        }


                        <Row justify="end">
                            {
                                !activeForm ?
                                    <Col xs={24}>
                                        <PrimaryButton onClick={() => handleSubmit()}>
                                            {t("Add.1")}
                                        </PrimaryButton>
                                    </Col>
                                    :
                                    <Col xs={24}>
                                        <PrimaryButton onClick={() => handleSubmit()}>
                                            Next
                                        </PrimaryButton>
                                        <GhostButton onClick={() =>
                                            refreshForm()
                                        }>
                                            Previous
                                        </GhostButton>
                                    </Col>
                            }
                        </Row>

                    </Row>

                    </Form>
                )}/>
        </div>
    );
})

export default PointFormLayout;