import React, {useState, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";


function SearchLocationInput(props) {
    const [query, setQuery] = useState("");
    const autoCompleteRef = useRef(null);
    let autoComplete;
    const {t} = useTranslation();

    const loadScript = (url, callback) => {
        let script = document.createElement("script");
        script.type = "text/javascript";

        if (script.readyState) {
            script.onreadystatechange = function () {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {
            script.onload = () => callback();
        }

        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    };

    function handleScriptLoad(updateQuery, autoCompleteRef) {
        autoComplete = new window.google.maps.places.Autocomplete(
            autoCompleteRef.current,
            {types: [], componentRestrictions: {country: "sa"}}
        );
        autoComplete.setFields(["address_components", "formatted_address", "geometry"]);
        autoComplete.addListener("place_changed", () =>
            handlePlaceSelect(updateQuery)
        );
    }

    async function handlePlaceSelect(updateQuery) {
        const addressObject = autoComplete.getPlace();
        const location = autoComplete.getBounds();
        const query = addressObject.formatted_address;
        updateQuery(query);
        console.log(addressObject);
        props.setFieldValue(props.name, query)
        if (props.name === "searching") {
            let latitude = addressObject.geometry.location.lat()
            let longitude = addressObject.geometry.location.lng()
            props.setFieldValue("any", {latitude: latitude, longitude: longitude, address: query,comment:"",id:"",map_link:"",title:""})
        } else {
            if (
                addressObject.geometry.location.lat() & addressObject.geometry.location.lng()
            ) {
                let latitude = addressObject.geometry.location.lat()
                let longitude = addressObject.geometry.location.lng()
                props.setFieldValue("latitude", latitude)
                props.setFieldValue("longitude", longitude)
                props.setFieldValue("map_link", `https://maps.google.com/maps?q=${latitude},${longitude}`)

            }
        }


    }
    useEffect(() => {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=AIzaSyDAke_aytn-eJJY9oNc15w-W309YQCuPxc&libraries=places`,
            () => handleScriptLoad(setQuery, autoCompleteRef)
        );
    }, []);

    return (
        <div className="search-location-input">
            <input
                className="ant-input ant-input-rtl sc-AxhUy jhjmHT"
                ref={autoCompleteRef}
                onChange={event => {
                    setQuery(event.target.value);
                    props.setFieldValue(props.name, event.target.value)

                }
                }
                placeholder={t("search")}
            />
        </div>
    );
}

export default observer (SearchLocationInput);