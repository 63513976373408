import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { observer } from "mobx-react-lite";
import gpsIcon from "../../../assets/icons-gps.svg";
import { mapStore } from "../map-store";
import { newPoints } from "./fake-points";
import greenIcon from "../../../assets/green-map-pin.svg";
import defaultIcon from "../../../assets/red-map-pin.svg";

const MapContainer = ({ isPickUp, google, onChange = false, style }) => {
  let [currentLoc, setCurrentLoc] = useState(
    mapStore.location || defaultLocation
  );

  let showAddress = "show address";

  let onMapClick = (a, b, c) => {
    if (!isPickUp) {
      mapStore.updateLocation({
        latitude: c.latLng.lat(),
        longitude: c.latLng.lng(),
        address: "address",
        comment: "",
        id: "",
        map_link: "",
        title: "default"
      });
      onChange && onChange(mapStore.location);
    }
  };

  useEffect(() => { });

  const selectPoint = point => {
    mapStore.selectPoint(point);
    mapStore.updatePoints(mapStore.points);
  };

  const CustomMarker = (point, onMarkerClick) => {
    return (
      <div onClick={onMarkerClick}>
        <Marker
          key={point.id}
          onClick={(point) => {
            selectPoint(point);
          }}
          icon={
            point.isSelected
              ? {
                url: greenIcon,
                anchor: new google.maps.Point(5, 58)
              }
              : {
                url: defaultIcon,
                anchor: new google.maps.Point(5, 58)
              }
          }
          position={{ lat: point.lat, lng: point.lng }}
        ></Marker>
      </div>
    )
  }

  const renderMarker = (pointsMarkers, isPickUpSelected) => {
    return isPickUpSelected ? (
      pointsMarkers.map(point => {
        return (
          <Marker
            key={point.id}
            onClick={() => {
              selectPoint(point);
            }}
            icon={
              point.isSelected
                ? {
                  url: greenIcon,
                  anchor: new google.maps.Point(5, 58)
                }
                : {
                  url: defaultIcon,
                  anchor: new google.maps.Point(5, 58)
                }
            }
            position={{ lat: point.lat, lng: point.lng }}
          ></Marker>
        );
      })
    ) : (
        <Marker
          key={mapStore.id}
          position={{
            lat: mapStore.location.latitude,
            lng: mapStore.location.longitude
          }}
        ></Marker>
      );
  };

  let onClickSingleLocation = id => { };

  const determineCenter = () => {
    console.log("Clicked")
    if (mapStore.selectedPoint !== undefined) {
      if (isPickUp && mapStore.selectedPoint !== undefined) {
        return {
          lat: mapStore.selectedPoint.lat,
          lng: mapStore.selectPoint.lng
        }
      } else {
        return {
          lat: mapStore.location.latitude,
          lng: mapStore.location.latitude
        }
      }
    }

  }
  return (
    <Map
      zoom={16}
      google={google}
      style={style}
      options={{
        styles: [{ stylers: [{ saturation: 10 }, { gamma: 0.5 }] }]
      }}
      center={{
        lat: isPickUp ? (mapStore.selectedPoint != undefined ? mapStore.selectedPoint.lat : mapStore.points[0].lat) : mapStore.location.latitude,
        lng: isPickUp ? (mapStore.selectedPoint != undefined ? mapStore.selectedPoint.lng : mapStore.points[0].lng) : mapStore.location.longitude
      }}
      initialCenter={
        {
          lat: isPickUp ? (mapStore.selectedPoint != undefined ? mapStore.selectedPoint.lat : mapStore.points[0].lat) : mapStore.location.latitude,
          lng: isPickUp ? (mapStore.selectedPoint != undefined ? mapStore.selectedPoint.lng : mapStore.points[0].lng) : mapStore.location.longitude
        }}
      // containerStyle={containerStyle}
    >
      {renderMarker(mapStore.points, isPickUp)}
    </Map>
  );
};

let defaultLocation = {
  latitude: 24.77426,
  longitude: 46.738586,
  address: "",
  comment: "",
  id: "",
  map_link: "",
  title: ""
},
  style = {
    width: "100%",
    height: "100%"
  };

export default GoogleApiWrapper({
  apiKey: "AIzaSyDAke_aytn-eJJY9oNc15w-W309YQCuPxc"
})(observer(MapContainer));
