import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./styles/my-theme.css";
import "./fonts/Tajawal-Regular.ttf";
import App from "./App";
import "./i18next";
import * as serviceWorker from "./serviceWorker";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
    apiKey: 'f85373920a49204f9d212bccaf218273',
    // apiKey: 'aby',
    plugins: [new BugsnagPluginReact()]
})

// @ts-ignore
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)


ReactDOM.render(
    <Suspense
        fallback={
            <div id="pageLoader">
                <div id="preloader">
                    <div id="status">
                        <div className="spinner">
                            <div className="double-bounce1"></div>
                            <div className="double-bounce2"></div>
                        </div>
                    </div>
                </div>
            </div>
        }
    >
        <ErrorBoundary>
            <App/>
        </ErrorBoundary>
    </Suspense>,
    document.getElementById("root")
);
serviceWorker.unregister();
