import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfigProvider, PageHeader, Result } from "antd";

import axios from "../../axios";
import i18next from "i18next";
import styled from "styled-components";
import ovalImage from "../../assets/oval-gradinant.svg";
import { AnimatePresence, motion } from "framer-motion";
import noulSmile from "../../assets/noul_succes.png";
import { Link } from "react-router-dom";
import RecoverFormComponent from "./recover-email-form/recover-email-form";



const StyeldWrapper = styled.div`
  background-image: url(${ovalImage});
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right -40em top;
  align-content: center;
  overflow: visible;
  height: 100vh;
`;

export default function Forgetpassword() {
  const { t } = useTranslation();
  const token =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZmI4YTY1Zjk5ZGZmYjIxZWZkOTVkZWMwMWFhYThiNzczNjNjNWY3MDE4YjIzZWEwZmQyZWE1NjZjODA3MmY5MzdiM2M5NjFhZDBmOTc1Y2UiLCJpYXQiOjE1ODk4Mjg4OTgsIm5iZiI6MTU4OTgyODg5OCwiZXhwIjoxNjIxMzY0ODk4LCJzdWIiOiIyNyIsInNjb3BlcyI6W119.TIRdpOlHga8x3XqET-iR9ohhG4SVchbjxPsbdg1aTcIJb6O3CjVYpsm7EIo3OCyLxQ1N-TjTB3v-XuM5uQLxeKRhOrXW03Tko5R-8AofLbUKKW6VqBCW3fLfhivWJVtFT21TZtzaGL9DmLW56eYpn3cV2ht8DTFFQnmWLW99BREX2z_9berGUI2PAQ6f_vft0AUhgoVXHqZsz6DL427uX0_Hen0FHRj-Qiohiqj4-lkljjLVlFV5mDcemhUQG7fHmsuOhaNByGjvwyV3OHoQe5riUQIDlLt4YLeRw_IFfjupG-hm91lZO_YvTz3wFrcze6a0lCKyMWIFVa2x49cr7d7gATLrS__j_F0uB4i0X5v3c0fXA4RG5E8OqQmyWWsyPqqDze9CoNT2sxgdOAHdZhj9nXZlR_DokTOH7QldTTm5acLc0UazZo5ZzUFdFSCUI6FQfTTuHjUxEGk0HLpNQUo7hqmC75t6oBzMvtFdAWs9fjklJ8_1MOr7tT0BJTVxNTla1I4xM51lvGVzEHaV1Niy1-B6yrWoMDwiuGVzjmnPVp_oIcj7jBJWdeEpNOldn8MI7zlA7pzdttfnp2tF-rttXHd1R0SV3d-kUR9Y8ENRgI5OtmQdThhgWJOeUWMmoXFb0jS4OP-KyWWw_JzFkuZiuFP7iscvNCeUGAyo9cs";
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState(false);


  // User @object
  const userData = {
    email: "",
  };

  let user = userData;

  //@action forget password
  const actionForgetPassword = (values: any) => {
    setIsLoading(true);
    axios
      .post("/V1/password/email", values, config)
      .then((_response) => {
        // authStore.cities = (response.data);
        setIsLoading(false);
        setErrors("");
        setSuccess(true);
        // let path = `/`;
        // window.location.href = path;
      })
      .catch((error) => {
        switch (error.response.status) {
          case 422:
            setErrors(t("WrongMail.1"));
            break;
        }
        setIsLoading(false);
      });
  };

  return (
    <StyeldWrapper>
      <ConfigProvider direction={i18next.dir()}>
        <PageHeader
          onBack={() => (window.location.href = "/login")}
          title="back to login "
        />
        <AnimatePresence>
          {success ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
              <Result
                icon={<img src={noulSmile} alt="noul_smile" />}
                title="تم ارسال رابط تغيير كلمة المرور لبريدك"
                extra={<Link to={"/login"}>{t("BackToLogin.1")}</Link>}
              />
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
              <RecoverFormComponent
                user={user}
                isLoading={isLoading}
                actionForgetPassword={actionForgetPassword}
                error={errors}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </ConfigProvider>
    </StyeldWrapper>
  );
}
