import React, { FC } from 'react';
import {observer} from "mobx-react-lite";
import {Card, Col, ConfigProvider, List, Row, Skeleton, Tag} from "antd";
import i18next from "i18next";
import styled from "styled-components";
import {colors} from "../../../styles/global";
import {device} from "../../../styles/device";
import {ILocation} from "../map-store";
import {EnvironmentOutlined} from "@ant-design/icons"
import editIcon from "../../../assets/material-edit.svg"
import checked from "../../../assets/awesome-check-circle.svg"
import {useTranslation} from "react-i18next";
interface OwnProps {
    item:ILocation,
    active:any,
    modalHandler ? :any
}

type Props = OwnProps;

const AddressCard: FC<Props> = (props) => {
    const { t } = useTranslation();
    return(
    <ConfigProvider direction={i18next.dir()}>
        <StyledCard>
            <List.Item
                key={props.item.id}
                style={{width: '-moz-available'}}

            >
                <Skeleton
                    avatar
                    title={false}
                    loading={false}
                    active
                >
                    <Row>

                        <Col xs={20}>
                            <Row>
                                <h3 className="icons-style"><EnvironmentOutlined className="icons-style"/>{props.item.address}
                                </h3>

                                <Col xs={24}>
                                    <Tag onClick={()=>props.modalHandler()} style={{color:'#5f5f5f',borderWidth:"1px",padding:'2em',borderColor:'#5f5f5f' ,fontSize:'14sp'}} ><img width={10} src={editIcon}/>{t("edit_address")}</Tag>
                                </Col>
                            </Row>
                        </Col>
                        {props.item.id === props.active ?  <Col xs={4} className="centered ">
                            <img src={checked}/>
                        </Col>:null
                        }

                    </Row>
                </Skeleton>
            </List.Item>
        </StyledCard>
    </ConfigProvider>
);
};

const StyledCard = styled(Card)`
  width:-moz-available;
  color: white;
  cursor: pointer;
  margin-bottom: 1em;
  border: ${colors.color_primary};
  h2{
  color: ${colors.color_primary};
  }
  h3,
  h4 {
    color: #161616;
    font-weight: 700;
  }

  border-radius: 8px;
  .user-dropdown-avatar {
    margin: 1em 0;
  }

.icons-style{
color: ${colors.dark_grey};
margin-inline-end: 8px;
}
  .ant-col.ant-col-xs-6.ant-col-rtl {
    vertical-align: middle;
    margin-top: 17%;
  }
  .ant-col-xs-18 {
    display: block;
    flex: none;
 max-width: 100%;
  }
   @media (${device.mobileL}) {
      height: fit-content;

    }
`;

export default observer(AddressCard);
