import React from "react";
import {Col, ConfigProvider, Form, Input, Row, Select as $Select} from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { colors } from "../../../../../styles/global";
import {  FormikValues , getIn } from "formik";
import { motion } from "framer-motion";
import {MyLabelMarg} from "../../../../global-styled-components/Labels";
import {Select} from "formik-antd";
import i18next from "i18next";
type Props = {
  values: FormikValues;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
};

export default function AccountFields({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
}: Props) {
  const { t } = useTranslation();

  return (
    <Form>
      <Row gutter={[16, 18]}>
        <Col xs={24}>
          <StyledForm label={t("Name.1")}>
            <Input
              placeholder="John Doe"
              type="name"
              size="middle"
              className="login-input"
              name="name"
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            />

            {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
            {touched.name && errors.name ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.7 }}
              >
                <div className="error-message">{errors.name}</div>
              </motion.div>
            ) : null}
          </StyledForm>
        </Col>
        <Col md={12} xs={24}>
          {/* --------------------------------------- --- ----------- */}
          {/*2- Email Input*/}
          <StyledForm label={t("Email.1")}>
            <Input
              placeholder="email@example.com"
              type="email"
              className="login-input"
              name="email"
              size="middle"
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />

            {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
            {touched.email && errors.email ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.7 }}
              >
                <div className="error-message">{errors.email}</div>
              </motion.div>
            ) : null}
          </StyledForm>
        </Col>
        {/* --------------------------------------- --- ----------- */}
        {/*8- Phone Input*/}

        <Col md={12} xs={24}>
          <StyledForm label={t("Phone_number.1")}>
            <ConfigProvider direction={i18next.dir()}>

            <Input
              placeholder={t("Phone_number.1")}
              type="text"
              size="middle"
              name="phone"
              maxLength={9}
              minLength={9}
              addonBefore="+966"
              className="login-input"
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
            />
            </ConfigProvider>

            {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
            {touched.phone && errors.phone ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.7 }}
              >
                <div className="error-message">{errors.phone}</div>
              </motion.div>
            ) : null}
          </StyledForm>
        </Col>

        <Col md={12} xs={24}>
          <StyledForm label={t("PageUrl.1")}>
            <Input
                placeholder="www.example.com"
                type="name"
                size="middle"
                className="login-input"
                name="website"
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.website}
            />

            {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
            {getIn(errors, 'website') && getIn(touched, 'website') ? (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.7 }}
                >
                  <div className="error-message">{getIn(errors, 'website')}</div>
                </motion.div>
            ) : null}
          </StyledForm>
        </Col>

        <Col md={12} xs={24}>
          <StyledForm>
            <MyLabelMarg>{t("ShipNo.1")}</MyLabelMarg>
            <Select
                name="order_per_month"
                className="login-input"
                size="large"
                style={{ width: "100%" }}
                optionFilterProp="children"
            >
              <$Select.Option value="" disabled>
                {t("ShipNo.1")}
              </$Select.Option>
              <$Select.Option value="0 - 10">
                0 - 10
              </$Select.Option>
              <$Select.Option value="10 - 50">
                10 - 50
              </$Select.Option>
              <$Select.Option value="50 - 200">
                50 - 200
              </$Select.Option>
              <$Select.Option value="200 - 500">
                200 - 500
              </$Select.Option>
              <$Select.Option value="More than 500 order">
                {t("ShipNo.2")}
              </$Select.Option>

            </Select>

            {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
            {getIn(errors, 'order_per_month') && getIn(touched, 'order_per_month')? (
                <div className="error-message">{getIn(errors, 'order_per_month')}</div>
            ) : null}
          </StyledForm>
        </Col>

        <Col md={24} xs={24}>
          <StyledForm label={t("ShipType.1")}>
            <Input
                placeholder={t("ShipType.1")}
                type="name"
                size="middle"
                className="login-input"
                name="product_description"
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.product_description}
            />

            {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
            {getIn(errors, 'product_description') && getIn(touched, 'product_description') ? (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.7 }}
                >
                  <div className="error-message">{getIn(errors, 'product_description')}</div>
                </motion.div>
            ) : null}
          </StyledForm>
        </Col>


        {/* --------------------------------------- --- ----------- */}
        {/*3- Password Input*/}
        <Col md={12} xs={24} style={{ paddingInlineEnd: ".3rem" }}>
          <StyledForm label={t("Password.1")}>
            <Input
              placeholder={t("Password.1")}
              type="password"
              size="middle"
              name="password"
              className="login-input"
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />

            {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
            {touched.password && errors.password ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.7 }}
              >
                <div className="error-message">{errors.password}</div>
              </motion.div>
            ) : null}
          </StyledForm>
        </Col>
        {/* --------------------------------------- --- ----------- */}
        {/*4- Confirm Password Input*/}
        <Col md={12} xs={24}>
          <StyledForm label={t("Password.2")}>
            <Input
              placeholder={t("Password.1")}
              type="password"
              size="middle"
              className="login-input"
              name="password_confirmation"
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password_confirmation}
            />

            {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
            {touched.password_confirmation && errors.password_confirmation ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.7 }}
              >
                <div className="error-message">
                  {errors.password_confirmation}
                </div>
              </motion.div>
            ) : null}
          </StyledForm>
        </Col>
      </Row>
    </Form>
  );
}

const StyledForm = styled(Form.Item)`
  margin: 0 0 0.2em 0;
  display: block;

  label {
    color: ${colors.color_primary_dark};
    font-weight: 700;
    ::after {
      content: none;
    }
  }
  input,
  .ant-select-selection.ant-select-selection--single {
    padding: 0.7em 1.5em;
    font-weight: 600;
    border-color: #e9edf3;
    ::placeholder {
      color: #dfe5ed;
    }
  }
  .ant-select-selection__placeholder {
    top: 0;
  }
  .ant-select-selection-selected-value {
    margin-top: -15px;
  }
`;
