import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import noulLogo from "../../../noul-logo-sm.png";
import { Formik } from "formik";
import { Form, Alert } from "antd";
import { PrimaryInput } from "../../global-styled-components/Inputs";
import { PrimaryButton } from "../../global-styled-components/Buttons";
import { AnimatePresence, motion } from "framer-motion";

interface OwnProps {
  user: any;
  isLoading: boolean;
  actionForgetPassword: (valus: any) => void;
  error: string;
}

type Props = OwnProps;

const RecoverFormComponent: FunctionComponent<Props> = ({
  user,
  isLoading,
  actionForgetPassword,
  error,
}) => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("EmailValidation.1"))
      .max(100, "*Email must be less than 100 characters")
      .required(t("InputRequaierd.1")),
  });

  return (
    <div className="password-reset">
      <img
        width={130}
        src={noulLogo}
        className="centered"
        style={{ marginBottom: "4em" }}
      />
      <h2
        style={{
          textAlign: "center",
          fontWeight: 700,
        }}
      >
        {t("Forget_password.1")}
      </h2>
      <h3 className="centered">{t("Forget_password.2")}</h3>
      <Formik
        initialValues={user}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          actionForgetPassword(values);
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form className="forget-password-form">
            <AnimatePresence>
              {error !== "" ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.6, ease: "easeInOut" }}
                >
                  <Alert message={error} type="error" />
                </motion.div>
              ) : null}
            </AnimatePresence>
            <Form.Item>
              <PrimaryInput
                placeholder="email@example.com"
                type="email"
                name="email"
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                style={{ marginBottom: 0, textAlign: "center" }}
              />
              {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
              {touched.email && errors.email && (
                <div className="error-message">{errors.email}</div>
              )}
            </Form.Item>

            <PrimaryButton
              htmlType="submit"
              onClick={() => handleSubmit()}
              style={{ width: "inherit" }}
              loading={isLoading}
            >
              {t("Submit.1")}
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RecoverFormComponent;
