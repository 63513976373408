import React, { FC, useEffect } from "react";
import { Row, Col, Button, message, Form, ConfigProvider } from "antd";
import GMap from "./GMap";
import SearchLocationInput from "../../Hub-Dashboard/assign-component/store-orders/order-details/SearchLocationInput";
import { observer } from "mobx-react-lite";
import { mapStore } from "../map-store";
import { clientAddLocation } from "../../../services/client-service";
import { useTranslation } from "react-i18next";
import gpsIcon from "../../../assets/icons-gps.svg";
import { ClientPrimaryInput } from "../../global-styled-components/Inputs";
import i18next from "i18next";

import {
  StyledButton,
  StyledFotter
} from "../../global-styled-components/Buttons";

interface OwnProps {
  modalHandler: any;
}
type Props = OwnProps;

const ClientMapLayout: FC<Props> = _props => {
  const { t } = useTranslation();
  const setFieldValue = (_tag: string, _value: any) => {
    if (_value.longitude !== undefined) {
      mapStore.updateLocation(_value);
      console.log(_value.address);
    }
  };
  useEffect(() => {
    getCurrentUserLocation();
  }, []);

  const getCurrentUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          console.log(pos);
          mapStore.updateLocation({
            latitude: pos.lat.toString(),
            longitude: `${pos.lng}`,
            address: "",
            comment: "",
            id: "",
            map_link: "",
            title: "default",
            isDefault: 0
          });
        },
        () => { }
      );
    }
  };

  const addNewAddress = (values: any) => {
    let location = mapStore.location;
    location!!.address = values.title;
    message.loading("loadding...");
    clientAddLocation(location)
      .then(_response => {
        _props.modalHandler();
        message.success("تم اضافة الموقع...");
      })
      .catch(_reason => {
        message.error("خطاء فاللأضافة");
      });
  };
  return (
    <Row style={{ height: "100%" }}>
      <Col xs={24}>
        <SearchLocationInput
          value={""}
          setFieldValue={setFieldValue}
          placeholder="Riyadh - Al-‘Olayya , Behind Alsaif Mosque"
          name={"searching"}
          className="login-input"
          style={{ padding: "3em", marginTop: "4em" }}
        />
        <Button
          className="current-location-button"
          icon={<img src={gpsIcon} />}
          onClick={() => getCurrentUserLocation()}
        />
        <GMap
          style={{ height: "90vh", width: "100vw" }}
          isPickUp={false} />
      </Col>
      <StyledFotter xs={24}>
        <ConfigProvider direction={i18next.dir()}>
          <Form
            style={{ background: "white" }}
            name="basic"
            initialValues={{ title: "" }}
            onFinish={addNewAddress}
            onFinishFailed={() => console.log("Error while finish")}
          >
            <Form.Item
              name="title"
              rules={[{ required: true, message: t("InputRequired.1") }]}
              label={"وصف الموقع"}
            >
              {/*<MyLabelMarg className="client-input-label"></MyLabelMarg>*/}
              <ClientPrimaryInput placeholder={t("title")} />
            </Form.Item>

            <Form.Item style={{ margin: "0" }}>
              <StyledButton htmlType="submit" className={"centered"}>
                تاكيد العنوان
              </StyledButton>
            </Form.Item>
          </Form>
        </ConfigProvider>
      </StyledFotter>
    </Row>
  );
};

// @ts-ignore
export default observer(ClientMapLayout);
