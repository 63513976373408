import React, {FunctionComponent} from 'react';
import {Row, Table,Col, Tag} from "antd";
import dayjs from "dayjs";
import {observer} from "mobx-react-lite";

interface OwnProps {
    tracking_history: any
}

type Props = OwnProps;

const OrderDetails: FunctionComponent<Props> = (props) => {

    const columns = [
        {
            title: 'customer_status',
            dataIndex: 'customer_status',
        },
        {
            title: 'updated_at',
            dataIndex: 'updated_at',
        }
        ]
    return (
        <>
                    <Row gutter={[16,16]}>
                            <Col xs={14}>
                                <h4 className={"text-color-dark"}>اخر تحديث لحالة الطلب</h4>
                                <h5 className={"text-color-primary"} style={{textAlign:'start'}} >{dayjs(props.tracking_history[0].updated_at).format('DD/MM/YYYY hh:mm A')}
                                </h5>
                            </Col>
                            <Col xs={10} className="centered">
                                <span className="centered" >
                                    {props.tracking_history[0].customer_status !== "arrived" ? (
                                        <Tag color="volcano" >
                                            {props.tracking_history[0].customer_status}
                                        </Tag>
                                    ) : (
                                        <Tag color="green" >
                                            {props.tracking_history[0].customer_status}
                                        </Tag>
                                    )}
                                  </span>
                            </Col>
                        <Col xs={24}>
                            <Table columns={columns} dataSource={props.tracking_history} size="middle" pagination={false} />
                        </Col>
                    </Row>

        </>
    );
};

export default observer(OrderDetails);
