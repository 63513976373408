import React from 'react';
import {Col, Row} from "antd";
import {MyLabel} from "../../../global-styled-components/Labels";
import {PrimaryInputTextArea, ProfileInput} from "../../../global-styled-components/Inputs";
import {GhostButton} from "../../../global-styled-components/Buttons";
import {useTranslation} from "react-i18next";

function PointAddressForm(props) {
    const { t } = useTranslation();

    return (

        <Row gutter={[16,16]}>
            <Col xs={24} >
                <MyLabel>{t("Address.1")}</MyLabel>
                <ProfileInput
                    name="address"
                    value={props.values.address}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    placeholder={t("Address.1")}
                />
                {props.touched.address && props.errors.address ? (
                    <div className="error-message">{props.errors.address}</div>
                ) : null}


            </Col>
            <Col xs={24} className={"marg-v-1"}>
                <MyLabel>{t("Map.label")}</MyLabel>
                <ProfileInput
                    name="title"
                    value={props.values.title}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    placeholder={t("Map.label")}
                />
                {props.touched.title && props.errors.title ? (
                    <div className="error-message">{props.errors.title}</div>
                ) : null}
            </Col>

            <Col xs={12} className={"marg-v-1"}>
                <MyLabel>{t("Map.lat")}</MyLabel>
                <ProfileInput
                    name="latitude"
                    value={props.values.latitude}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    placeholder={t("Map.lat")}
                />
                {props.touched.latitude && props.errors.latitude ? (
                    <div className="error-message">{props.errors.latitude}</div>
                ) : null}
            </Col>
            <Col xs={12} className={"marg-v-1"}>
                <MyLabel>{t("Map.lng")}</MyLabel>
                <ProfileInput
                    name="longitude"
                    value={props.values.longitude}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    placeholder={t("Map.lng")}
                />
                {props.touched.longitude && props.errors.longitude ? (
                    <div className="error-message">{props.errors.longitude}</div>
                ) : null}

                <Row justify="end" className="marg-v-1">
                    <GhostButton   onClick={async ()=>{
                        let url= props.values.map_link
                        let regex = new RegExp('@(.*),(.*),');
                        let lon_lat_match = url.match(regex);
                        if(lon_lat_match){

                            props.setFieldValue("latitude",lon_lat_match[1])
                            props.setFieldValue("longitude",lon_lat_match[2])
                        }
                    }} >
                        Generate Coordinates
                    </GhostButton>
                </Row>
            </Col>

            <Col xs={24} className={"marg-v-1"}>
                <MyLabel>{t("Map.2")}</MyLabel>
                <ProfileInput
                    name="map_link"
                    value={props.values.map_link}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    maxLength={1000}
                    placeholder={t("Map.1")}
                />
                {props.touched.map_link && props.errors.map_link ? (
                    <div className="error-message">{props.errors.map_link}</div>
                ) : null}
                <Row justify="end" className="marg-v-1">
                    <GhostButton   onClick={async ()=>{
                        // values.map_link =  `https://maps.google.com/maps?q=${values.latitude},${values.longitude}`
                        props.setFieldValue("map_link",`https://maps.google.com/maps?q=${props.values.latitude},${props.values.longitude}`)
                    }} >
                        Generate link
                    </GhostButton>
                </Row>

            </Col>

            <Col xs={24} className={"marg-v-1"}>
                <MyLabel>ملاحظات</MyLabel>
                <PrimaryInputTextArea
                    name="comment"

                    value={props.values.comment}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    placeholder="اضافة ملاحظات"

                />
                {props.touched.comment && props.errors.comment ? (
                    <div className="error-message">{props.errors.comment}</div>
                ) : null}
            </Col>

        </Row>
    );
}

export default PointAddressForm;