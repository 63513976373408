import { createContext } from "react";
import {observable } from "mobx";


// Add the default language in index 0
const locales = [
  {
    code: "ar",
    name: "عربي",
    dir: "rtl",
  },
  {
    code: "en",
    name: "English",
    dir: "ltr",
  },
];

const storageLanguage = localStorage.getItem("i18nextLng");

function getActiveLanguage(): string {

  /* the function returns a string
  when there is a language saved on the local storage
  else return default
  */
  if (storageLanguage) {
    return storageLanguage;
  } else {
    return locales[0].code;
  }
}

class LanguageStore {
  @observable activeLaguage: string = getActiveLanguage();
}

const LanguageStoreContext = createContext(new LanguageStore());
export default LanguageStoreContext;
