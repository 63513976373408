import React, { FunctionComponent } from 'react';
import styled from "styled-components";
import {Row,Col} from "antd";
import {colors} from "../../../styles/global";
import logoutIcon from "../../../assets/open-account-logout.svg"
import {logout} from "../../../services/auth-service";
import {useTranslation} from "react-i18next";
interface OwnProps {}

type Props = OwnProps;

const Header: FunctionComponent<Props> = (_props) => {

    const {t} = useTranslation();
    const actionLogout = () => {
        logout().then((_res)=>{
            localStorage.removeItem("token");
            let path = `/client`;
            window.location.href = path;
        }).catch((_err)=>{
            let path = `/client`;
            localStorage.removeItem("token");
            window.location.href = path;

        })
    };

  return (
      <>
      <StyledHeader>
          <Col xs={18}>
              <h3  className="text-color-white">{t("welcome")}</h3>
          </Col>
          < Col className='tx-aln-end' xs={6}>
              <a onClick={()=>actionLogout()}><img src={logoutIcon}/></a>
          </Col>
      </StyledHeader>
      </>
  );
};

const StyledHeader = styled(Row)`
background: ${colors.color_primary};
padding:  1.3em 2em;
color: white;
border-radius: 0 0 1em 1em;
`
export default Header;
