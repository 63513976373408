import React, { useState } from "react";
import { Layout, Menu, Modal, Tabs } from "antd";
import {
  HashRouter as Router,
  NavLink as Link,
  Route,
  Switch,
  useLocation
} from "react-router-dom";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import PointHome from "./point-home";
import Confirmation from "./Confirmation";
import RecievePage from "./recieve/RecievePage";
import UserPointState from "../../context/user-point/userPointState";
import AllOrders from "./AllOrders";
import ReleaseOrders from "./ReleaseOrders";
import Header from "./Header";
import { Home as HomeIcon } from "@styled-icons/boxicons-regular/Home";
import { Payment } from "@styled-icons/material-rounded/Payment";
import { User } from "@styled-icons/boxicons-regular/User";
import styled from "styled-components";
import "./user-point.scss";
import ProfileLayout from "../../Courier-Dashboard/profile-layout/profile-layout";
import ReceivedOrders from "./ReceivedOrders";
import ResetLoggedPassword from "../../Seller-Dashboard/Profile/ResetPassword";
import { useTranslation } from "react-i18next";
import { logout } from "../../../services/auth-service";
const { Footer } = Layout;

const UserPointIndex = () => {
  let location = useLocation();
  let [selected, setSelected] = useState([location.pathname]);
  const { t } = useTranslation();
  const actionLogout = () => {
    logout()
      .then(res => {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        localStorage.removeItem("user");
        let path = `/login`;
        window.location.href = path;
      })
      .catch(err => {
        let path = `/login`;
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        localStorage.removeItem("user");
        window.location.href = path;
      });
  };
  return (
    <UserPointState>
      <Header />
      <Router>
        <Layout
          style={{
            minHeight: "100vh",
            paddingBottom: "52px",
            paddingTop: "5rem"
          }}
          id="pointLayout"
        >
          <Switch>
            <Route path="/" exact component={PointHome} />
            <Route path="/orders" exact component={AllOrders} />
            <Route path="/recieve" exact component={RecievePage} />
            <Route path="/received" exact component={ReceivedOrders} />
            <Route path="/release" exact component={ReleaseOrders} />
            <Route
              path="/release/:tracking_id"
              exact
              component={Confirmation}
            />
            <Route
              path="/profile"
              component={() => (
                <>
                  <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab={t("Profile.1")} key="1">
                      <ProfileLayout
                        modalCollapse={() => console.log("update")}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("Password.1")} key="2">
                      <Layout.Content>
                        <ResetLoggedPassword
                          modalCollapse={() => console.log("update")}
                        />
                      </Layout.Content>
                    </Tabs.TabPane>
                  </Tabs>
                </>
              )}
            />
          </Switch>

          <Layout.Footer>
            <StyeledMenu
              theme="light"
              class="menu-center"
              mode="horizontal"
              selectedKeys={selected}
              onSelect={selectParam => {
                setSelected(selectParam.keyPath);
              }}
            >
              <Menu.Item key="1">
                {" "}
                <Link to="/">
                  <HomeIcon size={18} className="icon-fix" />
                </Link>
              </Menu.Item>
              <Menu.SubMenu
                className={"ant-menu-item"}
                icon={<User size={18} className="icon-fix" />}
              >
                <Menu.ItemGroup>
                  <Menu.Item key="3">
                    {" "}
                    <Link to="/profile">Profile</Link>
                  </Menu.Item>
                  <Menu.Item key="4" onClick={actionLogout}>
                    Logout
                  </Menu.Item>
                </Menu.ItemGroup>
              </Menu.SubMenu>
              {/*<Menu.Item key="3"> <Link to="/profile">*/}
              {/*  <User size={18} className="icon-fix"/>*/}
              {/*</Link></Menu.Item>*/}
            </StyeledMenu>
          </Layout.Footer>
        </Layout>
      </Router>
    </UserPointState>
  );
};

const footerIconStyles = {
  margin: "auto"
};
const StyeledMenu = styled(Menu)`
  height: 100%;

  padding: 0em;
  .menu-tag {
    color: white;
    margin: 2em 1em;
    cursor: default;
    font-weight: bold;
    display: block;
    opacity: 0.6;
  }
`;

export default UserPointIndex;
