import React, { useState, useContext, useEffect } from "react";
import Report from "./Report";
import "../style/colors.scss";
import "../style/overview.scss";
import trackingBlue from "../../../assets/orders/tracking-blue.svg";
import trackingYello from "../../../assets/orders/tracking-yello.svg";
import trackingActive from "../../../assets/orders/track-by-id.svg";
import wallet_icon from "../../../assets/orders/wallet_icon.svg";
import trackingGreen from "../../../assets/orders/delivered-icon.svg";
import { Card, Col, ConfigProvider, Row, Statistic } from "antd";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import axios from "../../../axios";
import { authStore } from "../../../stores/AuthStore";
const Overview = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({});
  let isLoading = false;
  let isError = false;

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: "application/json"
    }
  };
  const getPointsStatics = () => {
    isError = false;

    axios
      .get("/V1/point/analysis", config)
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.log("AXIOS ERROR in getPointStatics: ", error);
        isError = true;
      })
      .finally(() => {
        isLoading = false;
      });
  };
  useEffect(() => {
    getPointsStatics();
  }, []);

  return (
    <div className="Overview-container container">
      <ConfigProvider direction={i18next.dir()}>
        <div>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Card style={{ borderRadius: "7px" }} bordered={false}>
                <h3>
                  <img src={trackingBlue} />
                  <strong className="blue_light">
                    {t("PointStatus.total_order")}
                  </strong>
                </h3>
                <div className="cd-content">
                  {/* <Statistic className="mg-10px" style={{fontSize: '5px'}} value={112893} /> */}
                  <h3>{data.total_orders}</h3>
                  <h3>{t("Orders.2") + " " + data.total_orders}</h3>
                </div>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Card style={{ borderRadius: "7px" }} bordered={false}>
                <h3>
                  <img src={trackingYello} />
                  <strong className="warning">
                    {t("PointStatus.on_hold")}
                  </strong>
                </h3>
                <div className="cd-content">
                  <h3>{data.received_orders}</h3>
                  <h3>
                    {t("PointDetails.1") +
                      " " +
                      data.received_orders +
                      " " +
                      t("PointDetails.3")}
                  </h3>
                </div>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Card style={{ borderRadius: "7px" }} bordered={false}>
                <h3>
                  <img src={trackingGreen} width={58} />
                  <strong className="success">
                    {t("PointStatus.released")}
                  </strong>
                </h3>
                <div className="cd-content">
                  <h3>{data.released_orders}</h3>
                  <h3>
                    {t("PointDetails.2") +
                      " " +
                      data.released_orders +
                      " " +
                      t("PointDetails.4")}
                  </h3>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Card style={{ borderRadius: "7px" }} bordered={false}>
                <h3>
                  <img src={trackingActive} width={58} />
                  <strong className="">{t("PointStatus.active_points")}</strong>
                </h3>
                <div className="cd-content">
                  <h3>{data.active_points}</h3>
                  <h3>
                    {t("PointDetails.1") +
                      " " +
                      data.active_points +
                      " " +
                      t("PointDetails.5")}
                  </h3>
                </div>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Card style={{ borderRadius: "7px" }} bordered={false}>
                <h3>
                  <img src={wallet_icon} width={58} />
                  <strong className="success">{t("wallet")}</strong>
                </h3>
                <div className="cd-content">
                  <h3>{authStore.authUser.wallet}</h3>
                  <h3>
                    {t("wallet_desc") +
                      " " +
                      authStore.authUser.wallet +
                      " " +
                      t("SAR.1")}
                  </h3>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <Report />
      </ConfigProvider>
    </div>
  );
};

export default Overview;
