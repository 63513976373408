import React, {useContext, useEffect, useState} from 'react';
import {Formik} from "formik";
import {Form, message, Row, Col, ConfigProvider} from "antd";
import PointAddressForm from "../point-address-form";
import {GhostButton, PrimaryMenuButton,PrimaryButton} from "../../../../global-styled-components/Buttons";
import {PointAddressSchema} from "../../../../../validations/PointAddressSchema";
import {PointInfoSchema} from "../../../../../validations/PointInfoSchema";
import LocationFields from "../../../../Admin-Dashboard/add-account/new-account-form/account-fields/location-fields";
import {PointAccountSchema} from "../../../../../validations/PointAccountSchema";
import {observer} from "mobx-react-lite";
import RegisterStoreContext from "../../../../Register/registerStore";
import {useTranslation} from "react-i18next";
import AuthStoreContxt from "../../../../../stores/AuthStore";
import axios from "../../../../../axios"
import AuthStoreContext from "../../../../../stores/AuthStore";
import PointInfoInformationForm from "./point-info-information-form";
import PointInfoAddressForm from "./point-info-address-form";

const ShowPointInfoLayout = observer((props) => {

    let [activeForm, setActiveForm] = useState(false);
    let [activeSchema, setActiveSchema] = useState(0);
    const registerStore = useContext(RegisterStoreContext);
    const authStore = useContext(AuthStoreContext);
    let [point,setPoint] = useState()

    const {t} = useTranslation()

    useEffect(() => {
        registerStore.getRegion();
    },[])

      useEffect(() => {
        authStore.getPointProfile();
    },[])

    let pointUser = {

        name: authStore.pointProfile?.name,
        email: authStore.pointProfile?.email,
        commercial_file_url: authStore.pointProfile?.commercial_file_url,
        phone: authStore.pointProfile?.phone,
        point_id: authStore.pointProfile?.id,
        point_capacity: authStore.pointProfile?.point_capacity,
        point_cost_per_order:authStore.pointProfile?.point_cost_per_order,
        location:{
            title: authStore.pointProfile?.location.title,
            address: authStore.pointProfile?.location.address,
            comment: authStore.pointProfile?.location.comment,
            map_link: authStore.pointProfile?.location.map_link,
            latitude: authStore.pointProfile?.location.latitude,
            longitude: authStore.pointProfile?.location.longitude,
        }

    }

    const refreshForm = () => {
        setActiveForm(false);
        setActiveSchema(0);
    }
    return (
        <div>
            {authStore.pointProfile!==undefined ?
                <Formik
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        values.point_id = authStore.pointProfile.id

                        authStore.updatePointProfile(
                            values
                        );
                    }}
                    initialValues={pointUser}
                    validationSchema={PointInfoSchema}
                    render={({
                                 handleBlur,
                                 handleChange,
                                 handleSubmit,
                                 setFieldValue,
                                 values,
                                 errors,
                                 touched,

                             }) => (
                        <Form><Row>

                            {
                                !activeForm ? <>
                                    <PointInfoInformationForm
                                        handleChange={handleChange}
                                        values={values}
                                        handleBlur={handleBlur}
                                        errors={errors}
                                        touched={touched}
                                        style={{
                                            width:'100%'
                                        }}
                                    />
                                    <PointInfoAddressForm handleBlur={handleBlur}
                                                          handleChange={handleChange}
                                                          setFieldValue={setFieldValue}
                                                          values={values}
                                                          errors={errors}
                                                          touched={touched}/>
                                </> : null

                            }


                            <Row justify="end">
                                {
                                    !activeForm ?
                                        <Col xs={24}>
                                            <PrimaryButton onClick={() => handleSubmit()}>
                                                {t("Add.1")}
                                            </PrimaryButton>
                                        </Col>
                                        :
                                        <Col xs={24}>
                                            <PrimaryButton onClick={() => handleSubmit()}>
                                                Next
                                            </PrimaryButton>
                                            <GhostButton onClick={() =>
                                                refreshForm()
                                            }>
                                                Previous
                                            </GhostButton>

                                        </Col>

                                }
                            </Row>

                        </Row>
                        </Form>
                    )}/>:null
            }

        </div>
    );
})

export default ShowPointInfoLayout;