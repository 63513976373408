import React, { FC, useEffect } from "react";
import { Row, ConfigProvider } from "antd";
import GMap from "./GMap";
import { observer } from "mobx-react-lite";
import { mapStore, PickupPoint } from "../map-store";
// import { clientAddLocation } from "../../../services/client-service";
// import { useTranslation } from "react-i18next";
import i18next from "i18next";
import styled from "styled-components";
import PointCard from "./point-card"

import {
  StyledButton,
  StyledBottomContainer
} from "../../global-styled-components/Buttons";

interface OwnProps {
  modalHandler: any;
}
type Props = OwnProps;

const ClientPointsLayout: FC<Props> = _props => {
  // const { t } = useTranslation();


  useEffect(() => {
    getCurrentUserLocation();
  }, []);

  const getCurrentUserLocation = () => {
    // points.forEach(function (point) {});
  };

  const selectPoint = (point: PickupPoint) => {
    mapStore.selectPoint(point)
    mapStore.updatePoints(mapStore.points!!);
  };

  return (
    <Main className={"background-light"}>

      <MapContainer>
        <GMap
            style={{ height: "30vh", width: "100%" }}
            isPickUp={true} ></GMap>
      </MapContainer>

      <Container className={"background-light"}>

        <ListWrapper className={"background-light"} style={{ marginTop: "0.5em", padding: "0.5em" }}>

          <ConfigProvider direction={i18next.dir()}>
            <Row>

              {mapStore.points!!.map(value => {
                return (
                  <PointCard
                    key={value.id}
                    onClick={() => { selectPoint(value) }}
                    point={value}
                    isSelected={value.isSelected}
                  />
                );
              })}
            </Row>

          </ConfigProvider>
        </ListWrapper>
      </Container>
      <StyledBottomContainer >
        <StyledButton htmlType="submit" className={"centered"} style={{ marginTop: "10px" }}>
          تاكيد العنوان
                  </StyledButton>
      </StyledBottomContainer>
    </Main>
  );
};

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* for Firefox */
  min-height: 100px;
  
`;

const MapContainer = styled.div`  
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  flex-grow: 1;
  /* for Firefox */
  min-height: 30vh;
`;

const Main = styled.div`
/* give the outermost container a predefined size */

height: 100%;
position: relative;
display: flex;
flex-direction: column;
`;

const ListWrapper = styled.div`
display: flex;
flex-direction: column; 
  flex-grow: 1;
  
  overflow-y: auto;
  
  /* for Firefox */
  min-height: 0;
`;

// const MapStyled = styled(GMap)`
//   margin: 10px;

//   height: 200px;
// `;

// @ts-ignore
export default observer(ClientPointsLayout);
