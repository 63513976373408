import React, {FC,useState} from 'react';
import ReactInputVerificationCode from 'react-input-verification-code';
import {Row, Col, Button, message} from "antd";
import {ArrowRightOutlined} from "@ant-design/icons"
import {clientVerify} from "../../../services/auth-service";
interface OwnProps {
    phone: string
}

type Props = OwnProps;

const ClientVerificationCodeLayout: FC<Props> = (props) => {
    const [code, setCode] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const doVerify = (_code:string)=> {
        if (_code.length===4){
            console.log(_code)
            setIsLoading(true)
            verify({phone: props.phone, OTP: _code})
        }
    }

    const verify = (data:any)=>{
        clientVerify(data).then(_response => {
            message.success("Welcome")
            localStorage.setItem("token", _response.data.accessToken);
            let path = `/client/`;
            window.location.href = path;
        }).catch(_err => {
            message.error("wrong phone number")
        })
    }
        return (
        <Row className="centered" dir="ltr">
            <Col xs={24}>
                <ReactInputVerificationCode placeholder="" length={4} onChange={(code) => {
                    setCode(code)
                    doVerify(code)
                }
                }/>
            </Col>
            <Col xs={24} className="centered" style={{margin:'2em 0'}} >
                <Button
                    className="centered"
                    style={{padding:'1.8em'}}
                    type="primary" shape="circle"
                    icon={ <ArrowRightOutlined size={18} />}
                    onClick={()=>doVerify(code)}
                    disabled={code.length!==4}
                    loading={isLoading}
                    size="large">
                </Button>
            </Col>

        </Row>
    );
};

export default ClientVerificationCodeLayout;
