import HttpClient from '../services';

export const logout = async () => {

    const res = await HttpClient.get(
        `user/logout`);

    return res;
};

export const clientLogin = async (values:any)=>{
    const res = await  HttpClient.post(
        "auth/client/login",values
    )
    return res
}

export const clientVerify = async (values:any)=>{
    const res = await  HttpClient.post(
        "auth/client/verify-otp",values
    )
    return res
}