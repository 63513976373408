import React, { useState } from "react";
import "./menu-bar.style.scss";
import {
  Menu,
  Dropdown,

  Layout,
  Modal,
  ConfigProvider,
  Tabs,
} from "antd";
import Avatar from "react-avatar";
import {
  LogoutOutlined,
  MenuFoldOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
 import i18next from "../../i18next";
 import { useTranslation } from "react-i18next";
import HeaderWrapper from "../global-styled-components/HeaderWrapper";
 import { useObserver } from "mobx-react-lite";
import { authStore } from "../../stores/AuthStore";
import ProfileLayout from "../Courier-Dashboard/profile-layout/profile-layout";
import ResetLoggedPassword from "../Seller-Dashboard/Profile/ResetPassword";
import ShowPointInfoLayout from "../Point-Dashboard/points/forms/point-info/show-point-info-layout";
import {logout} from "../../services/auth-service";

const { Header } = Layout;
const { TabPane } = Tabs;
const { SubMenu } = Menu;
const { Content } = Layout;
const PointAdminMenuBar = ({ handelCollapse, collapsed , isMobile }) => {
  const { t } = useTranslation();
  const [profileCollapse, setProfileCollapse] = useState(false);

  const actionLogout = () => {
    logout().then((res)=>{
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.removeItem("user");
      let path = `/login`;
      window.location.href = path;

    }).catch((err)=>{
      let path = `/login`;
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.removeItem("user");
      window.location.href = path;

    })
  };

  const userMenu = (
    <Menu selectedKeys={[]}>
      <Menu.Item key="userCenter" onClick={() => setProfileCollapse(true)}>
        {/*<Link to="/">*/}
        <UserOutlined />
        Profile
        {/*</Link>*/}
      </Menu.Item>
      <Menu.Item key="logout" onClick={actionLogout}>
        <LogoutOutlined />
        Logout
      </Menu.Item>
    </Menu>
  );

  return useObserver(() => (
    <HeaderWrapper>
      <Header className="site-layout-background" style={{ padding: 0 }}>
        {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: "trigger",
            onClick: handelCollapse,
          }
        )}

        <div>
          <span
            className="i18n-select"
            onClick={() => i18next.changeLanguage(t("ActiveLanguage.key"))}
          >
            <span className="user-dropdown">{t("ActiveLanguage.name")}</span>
          </span>

          <Dropdown
            className="user-dropdown"
            overlay={userMenu}
            trigger={["click"]}
          >
            <span>
              <Avatar
                name={authStore.authUser.name}
                size="35"
                round={true}
                alt="avatar"
                src={authStore.authUser.img}
              />
              <span className="user-dropdown-text"></span>
            </span>
          </Dropdown>
        </div>
      </Header>

      <ConfigProvider direction={i18next.dir()}>
        <Modal
          visible={profileCollapse}
          width={isMobile ? "80%":"45%"}
          onCancel={() => setProfileCollapse(!profileCollapse)}
          destroyOnClose={true}
          footer={null}
        >
          <h3 style={{ fontWeight: "650" }}>{t("ProfileSettings.1")}</h3>
          <Tabs defaultActiveKey="1">
            <TabPane tab={t("Profile.1")} key="1">
              <ProfileLayout
                modalCollapse={() => setProfileCollapse(!profileCollapse)}
              />
            </TabPane>
            <TabPane tab={t("Password.1")} key="2">


              <Content>
                <ResetLoggedPassword
                    modalCollapse={() => setProfileCollapse(!profileCollapse)}
                />
              </Content>

            </TabPane>
            <TabPane tab={t("PointInfo.1")} key="3">


              <Content>
                <ShowPointInfoLayout
                />
              </Content>

            </TabPane>
          </Tabs>
        </Modal>
      </ConfigProvider>
    </HeaderWrapper>
  ));
};

const StyledMenuItem = styled(Menu.Item)`
  color: black;
  vertical-align: middle;
`;
export default PointAdminMenuBar;
