class IModelProfile {
  email: string = "";
  name: string = "";
  city_id: string = "";
  region_id: string = "";
  district_id: string = "";
  address: string = "";
  phone: string = "";
  app_key:string="";
}
export default IModelProfile;
