import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ConfigProvider, PageHeader, Result } from "antd";

import axios from "../../axios";
import { useLocation, Link } from "react-router-dom";
import i18next from "i18next";
import ovalImage from "../../assets/oval-gradinant.svg";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import ResetPasswordForm from "./reset-form-component/reset-form";
import noulSmile from "../../assets/noul_succes.png";

const StyeldWrapper = styled.div`
  background-image: url(${ovalImage});
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right -40em top;
  align-content: center;
  overflow: visible;
  height: 100vh;
`;
export default function NewPassword() {
  const { t } = useTranslation();
  const config = {
    headers: {
      Accept: "application/json",
    },
  };
  // match
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  let location = useLocation();
  let params = new URLSearchParams(location.search);

  // User @object
  const userData = {
    password: "",
    password_confirmation: "",
  };

  let user = userData;

  //@action forget password
  const actionForgetPassword = (values: any) => {
    setIsLoading(true);
    axios
      .post(
        `/V1/password/forgetPassword?token=${params.get(
          "token"
        )}&email=${params.get("email")}`,
        values,
        config
      )
      .then((_response) => {
        // authStore.cities = (response.data);
        setIsLoading(false);
        setSuccess(true);
        // let path = `/`;
        // window.location.href = path;
      })
      .catch((_error) => {
      });
  };

  return (
    <StyeldWrapper>
      <ConfigProvider direction={i18next.dir()}>
        <PageHeader
          onBack={() => (window.location.href = "/login")}
          title={t("BackToLogin.1")}
        />

        <AnimatePresence>
          {success ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
              <Result
                icon={<img src={noulSmile} alt="noul_smile" />}
                title="تم تحديث كلمة المرور بنجاح"
                extra={<Link to={"/login"}>{t("BackToLogin.1")}</Link>}
              />
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
              <ResetPasswordForm
                user={user}
                isLoading={isLoading}
                actionForgetPassword={actionForgetPassword}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </ConfigProvider>
    </StyeldWrapper>
  );
}
