import React from 'react';
import {Col, Row, Upload} from "antd";
import {MyLabel} from "../../../../global-styled-components/Labels";
import {ProfileInput} from "../../../../global-styled-components/Inputs";
import {useTranslation} from "react-i18next";
import {InboxOutlined} from "@ant-design/icons";

function PointInfoInformationForm(props) {
    const { t } = useTranslation();
    const commercialFileHandler = ({ file, onSuccess }) => {
        let fileObj = file;
        if(fileObj!==null){
            console.log(file.name)
            props.setFieldValue("commercial_register_file", fileObj);
            onSuccess("ok");
        }
    };
    return (
      <Row gutter={[0,16]} style={{width:'100%'}}>
          <Col xs={12} >
              <MyLabel>{t("Name.1")}</MyLabel>
              <ProfileInput
                  name="name"
                  value={props.values.name}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  placeholder={t("Name.1")}
              />
              {props.touched.name && props.errors.name ? (
                  <div className="error-message">{props.errors.name}</div>
              ) : null}


          </Col>

          <Col xs={12} >
              <MyLabel>{t("Email.1")}</MyLabel>
              <ProfileInput
                  name="email"
                  value={props.values.email}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  placeholder={t("Email.1")}
              />
              {props.touched.email && props.errors.email ? (
                  <div className="error-message">{props.errors.email}</div>
              ) : null}


          </Col>

{/*          <Col xs={24}>*/}
{/*              <MyLabel>{t("Company.cr_file")}</MyLabel>*/}
{/*              <Upload.Dragger*/}
{/*                  className="marg-2"*/}
{/*                  name="commercial_register_no"*/}
{/*                  accept=".pdf"*/}
{/*                  action="/upload.do"*/}
{/*                  multiple={false}*/}
{/*showUploadList={true}*/}
{/*                  customRequest={commercialFileHandler}*/}
{/*              >*/}
{/*                  <p className="ant-upload-drag-icon">*/}
{/*                      <InboxOutlined />*/}
{/*                  </p>*/}
{/*                  <p className="ant-upload-text">*/}
{/*                      Click or drag file to this area to upload*/}
{/*                  </p>*/}
{/*                  <p className="ant-upload-hint">Support for pdf files</p>*/}
{/*              </Upload.Dragger>*/}
{/*          </Col>*/}
      </Row>
    );
}

export default PointInfoInformationForm;