import React, { FunctionComponent, useEffect, useState } from "react";
import { Col, Radio, Row, Typography, Skeleton } from "antd";
import styled from "styled-components";
import mapIcon from "../../../assets/material-location-searching.svg";
import {
  clientGetLocations,
  clientGetOrders
} from "../../../services/client-service";
import { ILocation } from "../map-store";
import AddressCard from "./address-card";
import { RadioChangeEvent } from "antd/es/radio";
import OrderCard from "./order-card";
import { useTranslation } from "react-i18next";
interface OwnProps {
  modalHandler: any;
  pointsModalHandler: any;
  removeSelectedOrder: any;
  addSelectedOrder: any;
}

type Props = OwnProps;

const ClientMainLayout: FunctionComponent<Props> = props => {
  let [location, setLocation] = useState<ILocation>();
  let [secondaryLocation, setSecondaryLocation] = useState<ILocation>();
  let [activeLocation, setActiveLocation] = useState();
  let [orders, setOrders] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    clientGetLocations()
      .then(_response => {
        setLocation(_response.data.data.default);
        setSecondaryLocation(_response.data.data.secondary);
        setIsLoading(false);
      })
      .catch(reason => {
        console.log(reason);
      });
  }, []);

  useEffect(() => {
    clientGetOrders()
      .then(response => {
        console.log(response);
        setOrders(response.data.data);
      })
      .catch(reason => {
        console.log(reason);
      });
  }, []);

  useEffect(() => {
    orders.map(value => {
      props.addSelectedOrder(value.id);
    });
  }, [orders]);

  useEffect(() => {
    setActiveLocation(location ?.id!!);
  }, [location]);
  return (
    <>
      <Row>
        <Col xs={24} style={{ marginBottom: "1em" }}>
          <Typography.Title level={4}>{t("del_address")}</Typography.Title>
        </Col>
        <StyledLocationButton
          // style={{ marginBottom: "1em" }}
          onClick={props.modalHandler}
          xs={24}
          className="centered"
        >
          <img src={mapIcon} />
          <h4 className="text-color-dark" style={{ margin: "0 1.3em" }}>
            {t("select_location")}
          </h4>
        </StyledLocationButton>

        {/*<StyledLocationButton*/}
        {/*  style={{ marginBottom: "1em" }}*/}
        {/*  onClick={props.pointsModalHandler}*/}
        {/*  xs={24}*/}
        {/*  className="centered"*/}
        {/*>*/}
        {/*  <img src={mapIcon} />*/}
        {/*  <h4 className="text-color-dark" style={{ margin: "0 1.3em" }}>*/}
        {/*    {"اختيار نقطة التسليم"}*/}
        {/*  </h4>*/}
        {/*</StyledLocationButton>*/}

        <Col xs={24} style={{ marginBottom: "1em", backgroundColor:"red" }}>
          <Radio.Group
            style={{ width: "available" }}
            onChange={(e: RadioChangeEvent) =>
              setActiveLocation(e.target.value)
            }
          >
            {isLoading && <Skeleton active={isLoading} />}
            {location && (
              <Row>
                <Col style={{ width: "available" }} xs={24}>
                  <StyledRadioButton defaultChecked value={location.id}>
                    <AddressCard
                      active={activeLocation}
                      item={location}
                      modalHandler={props.modalHandler}
                    />
                  </StyledRadioButton>
                </Col>
              </Row>
            )}
            {secondaryLocation && (
              <Row>
                <Col style={{ width: "available" }} xs={24}>
                  <StyledRadioButton
                    defaultChecked
                    value={secondaryLocation.id}
                  >
                    <AddressCard
                      active={activeLocation}
                      item={secondaryLocation}
                      modalHandler={props.modalHandler}
                    />
                  </StyledRadioButton>
                </Col>
              </Row>
            )}
          </Radio.Group>
        </Col>
      </Row>
      <Row style={{ marginTop: "2em", paddingBottom: "5em" }}>
        <Col xs={24} style={{ marginBottom: "1em" }}>
          <Typography.Title level={4}>{t("my_orders")}</Typography.Title>
        </Col>

        {orders.map(value => {
          return (
            <OrderCard
              key={value.id}
              item={value}
              addSelectedOrder={props.addSelectedOrder}
              removeSelectedOrder={props.removeSelectedOrder}
            />
          );
        })}
      </Row>
    </>
  );
};

const StyledLocationButton = styled(Col)`
  background: white;
  padding: 1.3em;
  border-radius: 10px;
  cursor: pointer;
  h4 {
    margin-top: 0.7em;
    font-weight: 600;
  }
`;

const StyledRadioButton = styled(Radio.Button)`
  height: fit-content;
  margin-bottom: 0.8em;
  width: available !important;
`;

export default ClientMainLayout;
