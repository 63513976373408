import React, {useEffect, useState} from "react";
import {Row, Col} from "antd";
import NewAccountForm from "./forms/new-account-form";
import noulPointsImage from "../../assets/register-image.jpg";
import logoSmile from "../../assets/noul-smile-logo.svg";
import styled from "styled-components";
import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import {authStore} from "../../stores/AuthStore";

const StyeldCol = styled(Col)`
  background-image: url(${noulPointsImage});
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  height: 100vh;
  @media (max-width: 768px) {
    position: fixed;
    display: block;
    width: 100vw;
    height: 100vh !important;
  }
  @media (max-width: 425px) {
    position: fixed;
    display: block;
    width: 100vw;
  }
`;

const MyH2Message = styled.h1`
  color: white;
   bottom: 0;
  font-size: 30pt;
  font-weight: bold;
  margin: 40% 2em 1em 2em;
  @media (max-width: 1440px) {
    font-size: 24pt;
  }
  @media (max-width: 768px) {
    bottom: auto;
     margin: 4em 0 1em 0;
    font-size: 20pt;
     text-align: center;
  }
`;

const MyMessageContent = styled.li`
  color: white;
   bottom: 0;
  font-size: 18pt;
  font-weight: normal;
  margin: 1em 2em;
  @media (max-width: 1440px) {
    font-size: 24pt;
  }
  @media (max-width: 768px) {
    bottom: auto;
    margin-top: 0;
    font-size: 14pt;
    text-align: center;
          list-style-type: none;

  }
`;
const RegisterLayout = observer(() => {
    const {t} = useTranslation();
    let [collapsed, setCollapsed] = useState(false);
    useEffect(() => {
        if (authStore.seller_reg_status === true && !collapsed) {
            setCollapsed(true)
        }
    })
    return (
        <div style={{background: "white"}}>
            <Row>
                <StyeldCol lg={8} md={8} sm={24} xs={24}>
                    <a href="https://noul.net/">
                        <img width="16.5%" src={logoSmile} style={{margin: "4em"}}/>
                    </a>
                    {/*<MyH2Message>{t("FeedBackMessage.1")}</MyH2Message>*/}
                    <MyH2Message>{t("FeedBackMessage.2")}</MyH2Message>
                    {/*<MyH2Message>{t("FeedBackMessage.3")}</MyH2Message>*/}
                    {/*<MyH2Message>{t("FeedBackMessage.4")}</MyH2Message>*/}
                    {/*<MyH2Message>{t("FeedBackMessage.5")}</MyH2Message>*/}
                    <ul>
                        <MyMessageContent onClick={() => authStore.seller_reg_status = true}>
                            {t("FeedBackMessage.3")}
                        </MyMessageContent>
                        <MyMessageContent>
                            {t("FeedBackMessage.4")}
                        </MyMessageContent>
                        <MyMessageContent>
                            {t("FeedBackMessage.5")}
                        </MyMessageContent>
                    </ul>


                    {/*{authStore.cit}*/}
                </StyeldCol>

                <Col lg={24 - 8} md={24 - 8} sm={24} xs={24}>
                    <NewAccountForm/>
                </Col>

            </Row>
        </div>
    );
});

export default RegisterLayout;
