import React, {useState} from "react";
import {Row, Col, message} from "antd";
import "./Login.style.scss";
import logoLight from "../../assets/noul-light.png";
import logoSmile from "../../assets/noul-smile-logo.svg";

import { useTranslation } from "react-i18next";

import i18next from "../../i18next";
import ClientVerificationLayout from "./clinet/client-verification-layout";
import {clientLogin} from "../../services/auth-service";
import ClientVerificationCodeLayout from "./clinet/client-verification-code-layout";

function ClientLogin() {
  const { t } = useTranslation();
  const [phone,setPhone] = useState("");
  const [activeLayout,setActiveLayout] = useState(0);

  const doLogin = (data:any , isSubmitting:any)=>{
        clientLogin(data).then(_response => {
            isSubmitting(false)
            setPhone(data.phone)
            setActiveLayout(1)
            console.log(JSON.stringify(_response.data))
        }).catch(_err => {
            isSubmitting(false)
            message.error(_err.response.data.description)
        })
    }

  return (
    <section>
      <title>Login | Title</title>
      <Row style={{ minHeight: "100vh" }}>
        <Col xl={12} lg={11} md={24} xs={24} style={{ width: "100%" }}>
          <a href="https://noul.net/">
            <img className="smile-header" src={logoSmile} />
          </a>
          <span
            className="i18n-select lang-header"
            style={{
              float: t("ActiveLanguage.dir") === "rtl" ? "left" : "right",
            }}
            onClick={() => i18next.changeLanguage(t("ActiveLanguage.key"))}
          >
            <span className="user-dropdown">{t("ActiveLanguage.name")}</span>
          </span>

            {activeLayout === 0 ?  <div style={{ padding: "4em 4em 0 4em" }}>
                <h2
                    style={{
                        textAlign: "center",
                        fontWeight: 700,
                    }}
                >
                    {t("WelcomeTo.1")}{" "}
                    <abbr className="text-color-primary">{t("Noul.1")}</abbr>
                </h2>
                <h3 className="centered">{t("Login.3")}</h3>
                {/*<LoginForm />*/}

                <ClientVerificationLayout login={doLogin}/>
            </div> :

            <div style={{ padding: "4em 4em 0 4em" }}>
                <h2
                    style={{
                        textAlign: "center",
                        fontWeight: 700,
                    }}
                >
                    {t("we_sent_code")}{" "}

                </h2>

                <h2 dir="ltr" className="text-color-primary centered">+966 {phone}</h2>
                <ClientVerificationCodeLayout   phone={phone}/>
            </div>
            }

        </Col>

        <Col
          xl={12}
          lg={24 - 11}
          md={0}
          sm={0}
          xs={0}
          style={{
            backgroundColor: "#9c54d9",
            backgroundImage:
              "linear-gradient(147deg, #9c54d9 0%, #7c6db5 100%)",
            paddingTop: "16em",
          }}
        >
          <img
            src={logoLight}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBlockStart: "auto",
              display: "block",
              width: "200px",
              opacity: "92%",
            }}
          />
        </Col>
      </Row>
    </section>
  );
}

export default ClientLogin;
