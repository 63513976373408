import React, { FunctionComponent, useState, useEffect } from 'react';
import { Col, Row } from "antd";
import styled, { css } from "styled-components";
// import { useTranslation } from "react-i18next";
import locationIcon from "../../../assets/placeholder.svg";
import { mapStore } from "../map-store";

interface OwnProps {
    point: any,
    onClick: any,
    isSelected: boolean
}

type Props = OwnProps;

const PointCard: FunctionComponent<Props> = (props) => {

    let [style, setStyle] = useState("");
    // const { t } = useTranslation();


    useEffect(() => {
        if (props.point.isSelected) {
            console.log("changing color ", props.point.isSelected)
            setStyle("selected-card")
        }
    }, [mapStore.selectedPoint]);

    const onCardSelected = () => {
        props.onClick()
        setStyle("selected-card")
        console.log(style, "card is selected and style is")
    }


    return (

        <>
            <StyledLocationButton xs={24} onClick={() => { onCardSelected() }} selected={props.isSelected} >
                <Row >
                    <Col xs={24} >
                        <h2 className="text-color-primary">{props.point.name}</h2>
                    </Col>
                    <Col className='aln-center' xs={2} style={{ marginInline: "0.5em", alignItems: "center" }}>
                        <Icon src={locationIcon} />
                    </Col>
                    <Col xs={15}>
                        <h4 className={"text-color-dark"}>تفاصيل المكان</h4>
                    </Col>
                    <Col xs={4} style={{ textAlign: "left" }}>
                        <h4 className={"text-color-gray "}>36.6 كم</h4>
                    </Col>
                </Row>
            </StyledLocationButton>
        </>
    );
};

interface StyleProps {
    selected: boolean
}

const Icon = styled.img`
fill: red;
width: 1.5em;
height: 1.5em;
align-self: center;
`;

const StyledLocationButton = styled(Col) <StyleProps>`
background: white;
padding: 1.3em;
border-radius: 10px ;
margin-top: 1em;
margin-bottom: 1em;
border-color: white;
${({ selected }) =>
        selected &&
        css`
      border-color: #6B5CA8;
`}
      border-style: solid;
      border-width: 1px;
cursor: pointer;
h4{
margin-top: .7em;
font-weight: 600;
}
`;
export default PointCard;
