import React, { FC, useContext, useState } from "react";
import { Col, Form, Input, Row, Select as $Select } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { colors } from "../../../../../styles/global";
import { FormikValues } from "formik";
import RegisterStoreContext from "../../../../Register/registerStore";
import { MyLabelMarg } from "../../../../global-styled-components/Labels";
import { Select } from "formik-antd";
import { observer } from "mobx-react-lite";
import { motion } from "framer-motion";
import i18next from "i18next";
type Props = {
  values: FormikValues;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
};

const LocationFields: FC<Props> = observer(
  ({ values, errors, touched, handleBlur, handleChange }: Props) => {
    const { t } = useTranslation();
    const registerStore = useContext(RegisterStoreContext);
    const [cityLoading, setCityLoading] = useState(false);
    const [districtLoading, setDistrictLoading] = useState(false);
    return (
      <Form>
        <Row gutter={[16, 18]}>
          <Col xs={12}>
            <StyledForm>
              <MyLabelMarg>{t("Region.1")}</MyLabelMarg>
              <Select
                showSearch
                name="region_id"
                className="login-input"
                size="large"
                style={{ width: "100%" }}
                optionFilterProp="children"
                filterOption={(inputValue, option) =>
                  option!!.props.children
                    .toString()
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                onChange={() => {
                  if (registerStore.cities.length === 0) {
                    registerStore.getCity();
                    setCityLoading(true);
                  }
                }}
              >
                <$Select.Option value="" disabled>
                  {t("Region.1")}
                </$Select.Option>
                {registerStore.region.map((item) => {
                  return (
                    <$Select key={item.id} value={item.id}>
                      {" "}
                      {i18next.dir() === "rtl" ? item.name_ar : item.name_en}
                    </$Select>
                  );
                })}
              </Select>

              {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
              {touched.region_id && errors.region_id ? (
                <div className="error-message">{errors.region_id}</div>
              ) : null}
            </StyledForm>
          </Col>

          {/*6- City Input*/}
          <Col xs={12}>
            <StyledForm>
              <MyLabelMarg>{t("City.1")}</MyLabelMarg>

              <Select
                showSearch
                loading={cityLoading}
                size="large"
                name="city_id"
                className="login-input"
                style={{ width: "100%" }}
                optionFilterProp="children"
                filterOption={(inputValue, option) =>
                  option!!.props.children
                    .toString()
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                disabled={values.region_id === "" ? true : false}
                onChange={() => {
                  if (registerStore.districs.length === 0) {
                    setDistrictLoading(true);
                    registerStore.getDistrict();
                  }
                }}
              >
                <$Select.Option value="" disabled>
                  City
                </$Select.Option>
                {registerStore.cities.map((item) => {

                  if (cityLoading) {
                    setCityLoading(false);
                  }
                  if (item.region_id === values.region_id)
                    return (
                      <$Select key={item.id} value={item.id}>
                        {" "}
                        {i18next.dir() === "rtl" ? item.name_ar : item.name_en}
                      </$Select>
                    );
                  // }
                })}
              </Select>

              {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
              {touched.city_id && errors.city_id ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.7 }}
                >
                  <div className="error-message">{errors.city_id}</div>
                </motion.div>
              ) : null}
            </StyledForm>
          </Col>
          {/* --------------------------------------- --- ----------- */}

          {/*7- District*/}
          <Col xs={24}>
            <StyledForm>
              <MyLabelMarg>{t("District.1")}</MyLabelMarg>

              <Select
                showSearch
                loading={districtLoading}
                size="large"
                name="district_id"
                className="login-input"
                style={{ width: "100%" }}
                optionFilterProp="children"
                filterOption={(inputValue, option) =>
                  option!!.props.children
                    .toString()
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                disabled={values.city_id === "" ? true : false}
              >
                <$Select.Option value="" disabled>
                  {t("District.1")}
                </$Select.Option>
                {registerStore.districs.map((item) => {
                  // if (item.isActive === 1) {
                  if (districtLoading) {
                    setDistrictLoading(false);
                  }
                  if (values.city_id === item.city_id) {
                    return (
                      <$Select key={item.id} value={item.id}>
                        {" "}
                        {i18next.dir() === "rtl" ? item.name_ar : item.name_en}
                      </$Select>
                    );
                  }
                  // }
                })}
              </Select>

              {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
              {touched.district_id && errors.district_id ? (
                <div className="error-message">{errors.district_id}</div>
              ) : null}
            </StyledForm>
          </Col>
          {/* --------------------------------------- --- ----------- */}

          {/*7- Address*/}
          <Col xs={24}>
            <StyledForm label={t("Address.1")}>
              <Input
                placeholder="Address"
                type="address"
                name="address"
                size="middle"
                className="login-input"
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.address}
              />

              {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
              {touched.address && errors.address ? (
                <div className="error-message">{errors.address}</div>
              ) : null}
            </StyledForm>
          </Col>

        </Row>
      </Form>
    );
  }
);

const StyledForm = styled(Form.Item)`
  margin: 0 0 0.2em 0;
  display: block;

  label {
    color: ${colors.color_primary_dark};
    font-weight: 700;
    ::after {
      content: none;
    }
  }
  input,
  .ant-select-selection.ant-select-selection--single {
    padding: 0.7em 1.5em;
    font-weight: 600;
    border-color: #e9edf3;
    ::placeholder {
      color: #dfe5ed;
    }
  }
  .ant-select-selection__placeholder {
    top: 0;
  }
  .ant-select-selection-selected-value {
    margin-top: -15px;
  }
`;

export default LocationFields;
