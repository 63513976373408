import { action, observable } from "mobx";
import { createContext } from "react";
import fakePoints from "./map-layout/fake-points";

export interface ILocation {
  longitude: string;
  latitude: string;
  address: any;
  title: string;
  comment: string;
  map_link: string;
  id: any;
  isDefault: number;
}

export interface PickupPoint {
  id: number;
  name: string;
  lat: number;
  lng: number;
  isSelected: boolean;
}

class MapStore {
  @observable location?: ILocation = {
    longitude: "46.738586",
    latitude: "24.77426",
    address: "",
    comment: "",
    id: "",
    map_link: "",
    title: "",
    isDefault: 0
  };

  @observable points?: PickupPoint[] = fakePoints;
  @observable selectedPoint?: PickupPoint;
  @observable isLoading: boolean = false;
  @observable isError: boolean = false;
  @observable errors: string = "";

  @action
  updateLocation = async (newLocation: ILocation) => {
    this.location = newLocation;
  };

  @action
  updatePoints = async (newPoints: PickupPoint[]) => {
    if (this.selectedPoint != undefined) {
      let thePoint = this.selectedPoint
      var thePoints = newPoints;
      thePoints.forEach(function (point) {
        if (point.id == thePoint.id) {
          point.isSelected = true;
        } else {
          point.isSelected = false;
        }
      });
      this.points = thePoints;
    } else {
      this.points = newPoints;
    }
  };

  @action
  selectPoint = async (point: PickupPoint) => {
    this.selectedPoint = point;
  };
}

export const mapStore = new MapStore();
const MapStoreContext = createContext(new MapStore());
export default MapStoreContext;
