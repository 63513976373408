import HttpClient from '../services';

export const logout = async () => {

    const res = await HttpClient.get(
        `user/logout`);

    return res;
};

export const clientGetLocations = async ()=>{
    const res = await  HttpClient.get(
        "client/address/list"
    )
    return res
}

export const clientAddLocation = async (values:any)=>{
    const res = await  HttpClient.post(
        "client/address/add",values
    )
    return res
}
export const clientGetOrders = async ()=>{
    const res = await  HttpClient.get(
        "client/order/list"
    )
    return res
}

export const clientGetOrderDetails = async (id:any)=>{
    const res = await  HttpClient.get(
        `client/order/${id}`
    )
    return res
}

export const clientUpdateOrders = async (values:any)=>{
    const res = await  HttpClient.post(
        "client/order/update",{
            service_type:0,
            address_id:1,
            order_ids:values
        }
    )
    return res
}
