import React, { Fragment, useContext } from "react";
import { Form } from "antd";
import { MyLabel } from "../global-styled-components/Labels";
import { PrimaryInput } from "../global-styled-components/Inputs";
import { PrimaryButton } from "../global-styled-components/Buttons";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import AuthStoreContext from "../../stores/AuthStore";

const LoginForm: React.FC = observer(() => {
  const { t } = useTranslation();
  const authStore = useContext(AuthStoreContext);
  // User @object
  const userData = {
    email: "",
    password: "",
  };

  let user = userData;

  // Yup Validations
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("EmailValidation.1"))
      .max(100, "*Email must be less than 100 characters")
      .required(t("InputRequired.1")),
    password: Yup.string()
      .min(4, t("FourChartValidation.1"))
      .max(32)
      .required(t("InputRequired.1")),
  });

  return (
    <Fragment>
      <Formik
        initialValues={user}
        onSubmit={(values, { setSubmitting }) => {
            // Connect with actionLogin from AuthStore.ts
            authStore.actionLogin(values);
          setSubmitting(true);
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form className="login-form">
            <Form.Item>
              <MyLabel>{t("Email.1")}</MyLabel>
              <PrimaryInput
                placeholder="email@example.com"
                type="email"
                name="email"
                autoComplete="on"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />

              {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
              {touched.email && errors.email ? (
                <div className="error-message">{errors.email}</div>
              ) : null}
            </Form.Item>
            <Form.Item>
              <MyLabel>{t("Password.1")}</MyLabel>

              <PrimaryInput
                type="password"
                placeholder="Password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />

              {/* Check if error show the error message */}
              {touched.password && errors.password ? (
                <div className="error-message">{errors.password}</div>
              ) : null}
            </Form.Item>
            <Link className="login-form-forgot ?" to="/reset">
              {t("Forget_password.1")}
            </Link>
            {/* Here i should send the data to the API*/}
            <PrimaryButton
              className="login-form-button"
              onClick={() => handleSubmit()}
            >
              {t("Login.2")}
            </PrimaryButton>
            {t("Or.1")} <Link to="/register">{t("Regiester_here.1")}</Link>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
});

export default LoginForm;
