import * as Yup from "yup";

export const PointAddressSchema = Yup.object().shape({
  address: Yup.string()
    .max(128, "العنوان لايتجاوز 128 خانه").required(),

  title: Yup.string()
    .max(32)
    ,

  comment: Yup.string()
      .max(128, "العنوان لايتجاوز 128 خانه")
      ,

  map_link: Yup.string().max(1000),
  latitude: Yup.string(),
  longitude: Yup.string(),

});
