import * as Yup from "yup";
import i18next from "i18next";
const phoneRegExp = new RegExp(/(5)[0-9]{8}/);

export const PointAccountSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18next.t("EmailValidation.1"))
    .max(100, "*Email must be less than 100 characters")
    .required(i18next.t("InputRequired.1")),

  password: Yup.string()
    .min(4, i18next.t("FourChartValidation.1"))
    .max(32)
    .required(i18next.t("InputRequired.1")),

  password_confirmation: Yup.string()
    .min(4, i18next.t("FourChartValidation.1"))
    .max(32)
    .required(i18next.t("InputRequired.1"))
    .oneOf([Yup.ref("password")], "Password must match"),
  name: Yup.string()
    .min(4, i18next.t("FourChartValidation.1"))
    .max(32)
    .required(i18next.t("InputRequired.1")),

  district_id: Yup.string().required(i18next.t("InputRequired.1")),

  city_id: Yup.string().required(i18next.t("InputRequired.1")),

  address: Yup.string()
    .min(4, i18next.t("FourChartValidation.1"))
    .max(128)
    .required(i18next.t("InputRequired.1")),

  phone: Yup.string()
    .matches(phoneRegExp, i18next.t("PhoneRegValidation.1"))
    .required(i18next.t("InputRequired.1")),
    point_capacity: Yup.number().required(i18next.t("InputRequired.1"))
});
