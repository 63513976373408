import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {Formik} from "formik";
import {ConfigProvider, Form} from "antd";
import {MyLabel} from "../../global-styled-components/Labels";
import {PrimaryInputFull} from "../../global-styled-components/Inputs";
import {PrimaryButton} from "../../global-styled-components/Buttons";
import i18next from "i18next";

interface OwnProps {
    login:any
}

type Props = OwnProps;

const ClientVerificationLayout: FC<Props> = (props) => {

    const { t } = useTranslation();
    const phoneRegExp = new RegExp(/(5)[0-9]{8}/);



    // User @object
    const userData = {
        phone: ""
    }

    let user = userData;

    // Yup Validations
    const validationSchema = Yup.object().shape({
        phone: Yup.string()
            .matches(phoneRegExp, i18next.t("PhoneRegValidation.1"))
            .required(i18next.t("InputRequired.1")),
    });

    return (
        <>
            <Formik
                initialValues={user}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    props.login(values,setSubmitting)
                }}
                validationSchema={validationSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    isSubmitting
                  }) => (
                    <Form className="login-form">
                        <Form.Item >
                            <ConfigProvider direction={i18next.dir()}>
                            <MyLabel>{t("Phone.1")}</MyLabel>
                                <PrimaryInputFull
                                    placeholder="5xxxxxxx"
                                    type="phone"
                                    name="phone"
                                    autoComplete="on"
                                    addonBefore={"+966"}
                                    dir={"ltr"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.phone}
                                />

                                {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
                                {touched.phone && errors.phone ? (
                                    <div className="error-message">{errors.phone}</div>
                                ) : null}
                            </ConfigProvider>


                        </Form.Item>

                        {/* Here i should send the data to the API*/}
                        <PrimaryButton
                            className="login-form-button"
                            onClick={() => handleSubmit()}
                            loading={isSubmitting}
                        >
                            {t("Login.2")}
                        </PrimaryButton>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ClientVerificationLayout;
