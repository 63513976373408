export interface PickupPoint {
  id: number;
  name: string;
  lat: number;
  lng: number;
  isSelected: boolean;
}

const points: PickupPoint[] = [
  {
    id: 1,
    name: "العزيزية",
    lat: 21.3708542,
    lng: 39.8821414,
    isSelected: false
  },
  {
    id: 2,
    name: "الخالدية",
    lat: 21.3692298,
    lng: 39.8816595,
    isSelected: false
  },
  {
    id: 3,
    name: "الشوقية",
    lat: 21.3722686,
    lng: 39.8835323,
    isSelected: false
  }, {
    id: 4,
    name: "الشوقية",
    lat: 21.3722686,
    lng: 39.8821414,
    isSelected: false
  }
];

export const newPoints: PickupPoint[] = [
  {
    id: 1,
    name: "البيت",
    lat: 21.3994218,
    lng: 39.8007607,
    isSelected: false
  },
  {
    id: 2,
    name: "الدائري",
    lat: 21.3994076,
    lng: 39.8014307,
    isSelected: false
  }
];

export default points;
