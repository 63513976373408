import React from "react";
import { Layout } from "antd";
import Profile from "../../Seller-Dashboard/Profile/Profile";
const { Content } = Layout;

type Props = {
  modalCollapse: any;
};

export default function ProfileLayout({ modalCollapse }: Props) {
  return (
    <>
      <Content style={{ minHeight: 280 }}>
        <Profile modalCollapse={modalCollapse} />
      </Content>
    </>
  );
}
