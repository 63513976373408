import React, { FC, useEffect, useState } from "react";
import Header from "./header/header";
import ClientMainLayout from "./main-layout/client-main-layout";
import { Button, Drawer, message, Result, ConfigProvider } from "antd";
import ClientMapLayout from "./map-layout/map-layout";
import ClientPointsLayout from "./map-layout/points-layout";
import styled from "styled-components";
import { colors } from "../../styles/global";
import _ from "lodash";
import { clientUpdateOrders } from "../../services/client-service";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { authStore } from "../../stores/AuthStore";
import ClientLogin from "../Login/ClientLogin";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

interface OwnProps { }

type Props = OwnProps;
interface MyOrders {
  id: number;
}
const ClientIndex: FC<Props> = _props => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPointsModalVisible, setPointsModalVisible] = useState(false);
  let [selected, setSelected] = useState<MyOrders[]>([]);
  let [loading, setLoading] = useState(false);
  let history = useHistory();
  const { t } = useTranslation();
  const addSelectedOrder = async (id: any) => {
    setLoading(true);
    let _selected = selected;
    let order: MyOrders = { id: id };
    await _selected.push(order);
    setSelected(_selected);
    setLoading(false);
  };
  const removeSelectedOrder = async (id: any) => {
    setLoading(true);
    let _selected = _.remove(selected, (item: MyOrders) => {
      return item.id === id;
    });
    setSelected(_selected);
    setLoading(false);
  };

  useEffect(() => {
    console.log(selected.length);
  }, [selected]);

  const updateOrdersToDeliver = () => {
    message.loading("loading...");
    clientUpdateOrders(selected)
      .then(_response => {
        message.destroy();
        history.push("/client/done");
      })
      .catch(reason => {
        message.destroy();
        message.error(reason.response.data.error);
      });
  };

  const modalHandler = () => {
    setIsModalVisible(!isModalVisible);
  };

  const pointsModalHandler = () => {
    setPointsModalVisible(!isPointsModalVisible);
  };

  return (
    <>
      {!authStore.authToken ? (
        <ClientLogin />
      ) : (
          <BrowserRouter>
            <ConfigProvider direction={i18next.dir()}>
              <StyledDrawer
                title="تحديد العنوان"
                placement={"bottom"}
                closable={true}
                onClose={modalHandler}
                visible={isModalVisible}
                height={"100%"}
              >
                <ClientMapLayout modalHandler={modalHandler} />
              </StyledDrawer>

              <StyledDrawer
                title="تحديد نقطة الاستلام"
                placement={"bottom"}
                closable={true}
                onClose={pointsModalHandler}
                visible={isPointsModalVisible}
                height={"100%"}
              >
                <ClientPointsLayout modalHandler={pointsModalHandler} />
              </StyledDrawer>
            </ConfigProvider>
            <Header />
            <Switch>
              <Route path="/client/" exact={true}>
                <motion.div
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                  }}
                >
                  <div style={{ margin: "3em 2em" }}>
                    <ClientMainLayout
                      addSelectedOrder={addSelectedOrder}
                      removeSelectedOrder={removeSelectedOrder}
                      modalHandler={modalHandler}
                      pointsModalHandler={pointsModalHandler}
                    />
                  </div>
                  <MyStyledButton
                    loading={loading}
                    disabled={selected.length < 1}
                    className={"centered"}
                    onClick={updateOrdersToDeliver}
                  >
                    {t("confirm_delivery")}
                  </MyStyledButton>
                </motion.div>
              </Route>

              <Route path="/client/done">
                <motion.div
                  initial={{ scale: 0, rotate: 180 }}
                  animate={{ rotate: 0, scale: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                  }}
                >
                  <Result
                    status="success"
                    title="شكراً لك"
                    subTitle="تم تاكيد موقع الاستلام وجاري العمل على توصيل طلباتك"
                    style={{ margin: "12em 0 0 0" }}
                  />
                </motion.div>
              </Route>
            </Switch>
          </BrowserRouter>
        )}
    </>
  );
};

const StyledDrawer = styled(Drawer)`

  .ant-drawer-body {
    padding: 0;
  }
  .ant-drawer-header {
    background: ${colors.color_primary};
    padding: 1.3em 2em;
    color: white;
    border-radius: 0 0 1em 1em;
  }
  .ant-drawer-title,
  .ant-drawer-close {
    color: white;
    font-weight: 500;
  }
  .search-location-input {
    margin: 2em;
    /* padding: 1em; */
    position: absolute;
    margin-top: 2em;
    z-index: 1000;
    width: -webkit-fill-available;
    input {
      padding: 0.7em;
    }
  }
  .current-location-button {
    margin: 5em 1.8em ;
    /* padding: 1em; */
    position: absolute;
    z-index: 1000;

    input {
      padding: 0.7em;
    }
  }
  input.ant-input.ant-input-rtl.sc-AxhCb.jChcpY {
    :hover,
    :focus {
      border: none !important;
      outline: none;
      box-shadow: none;
    }
  }
  .ant-form-item-required {
    margin: 1em 2em 0 2em !important;
    text-align: start;
  }
  .ant-col.ant-col-rtl {
    float: none !important;
    text-align: start;
  }
  .gm-style-mtc {
    display: none;
  }
  .gm-svpc {
    display: none;
  }
  .gm-fullscreen-control {
    display: none;
  }
  .gm-control-active {
    top: 30%;
  }
  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    margin: 10px !important;
    user-select: none !important;
    position: absolute !important;
    bottom: 194px !important;
    right: 40px !important;
  }
`;
const MyStyledButton = styled(Button)`
  background: ${colors.color_primary};
  padding: 2em 2em;
  color: white;
  width: 100vw;
  border: none;
  margin: 0;
  position: fixed;
  bottom: 0;
  border-radius: 1em 1em 0 0;
  :hover {
    color: white;
    background-color: ${colors.lightPurple};
    outline: ${colors.blue_light};
    border-color: ${colors.lightPurple} !important;
  }

  :focus {
    color: white;
    background-color: ${colors.lightPurple};
    outline: ${colors.blue_light};
  }
`;
export default observer(ClientIndex);
