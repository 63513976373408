import React, { lazy, useContext, Suspense } from "react";
import { Redirect , useLocation } from "react-router-dom";
import AuthStoreContext from "../stores/AuthStore";
import { observer } from "mobx-react-lite";
import PointIndex from "../components/Point-Dashboard/point-index";

import UserPointIndex from "../components/Point-Dashboard/user-point/User-point";
import ClientIndex from "../components/Client-Dashboard/client-index";
import Login from "../components/Login/Login";


// Import all the component as a lazy load components
const SellerDashboard = lazy(() =>
  import("../components/Seller-Dashboard/SellerIndex")
);
const AdminDashboard = lazy(() =>
  import("../components/Admin-Dashboard/admin-index")
);
const CourierDashboard = lazy(() =>
  import("../components/Courier-Dashboard/courier-index-layout")
);

const HubDashboard = lazy(() =>
  import("../components/Hub-Dashboard/hub-index")
);

const Protectedroute = observer((_props: any) => {

  // connect with auth store to check the authentication status and details
  const authStore = useContext(AuthStoreContext);
  const location = useLocation();
  const currentPath = location.pathname;
  console.log(currentPath)
  const renderSwitch = () => {
    if (authStore.authRole === "1") {
      return <AdminDashboard />;
    } else if (authStore.authRole === "2") {
      return <SellerDashboard />;
    } else if (authStore.authRole === "3") {
      return <HubDashboard />;
    }
    else if (authStore.authRole === "4"){
      return <PointIndex/>
    }
    else if (authStore.authRole === "5") {
      return <CourierDashboard />;
    }
    else if (authStore.authRole === "6") {
       return <UserPointIndex />;
    }
    // when user try to login from portal.noul.net/client
    else if (currentPath.includes("client/")) return <ClientIndex/>
    else return <Login/>

   };
  return authStore.authToken !== ""  ? (
    <Suspense
      fallback={
        <div id="pageLoader">
          <div id="preloader">
            <div id="status">
              <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
              </div>
            </div>
          </div>
        </div>
      }
    >
      {renderSwitch()}
    </Suspense>
  ) : (
    <Redirect to={{ pathname: "/login" }} />
  );
});

export default Protectedroute;
