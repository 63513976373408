import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from "react-router-dom";
import axios from "../../../../../axios"
import trackingBlue from '../../../../../assets/orders/tracking-blue.svg';
import trackingYello from '../../../../../assets/orders/tracking-yello.svg';
import trackingGreen from '../../../../..//assets/orders/order-success-icon.svg';
import styled from "styled-components";
import { Card, Col, Row, Button, Select, Tag, ConfigProvider, PageHeader} from "antd";
import {EnvironmentOutlined, PhoneOutlined , MoreOutlined} from "@ant-design/icons";
import PointReport from './PointReport';
import i18next from "i18next";
import {useTranslation} from "react-i18next";

const { Option } = Select;

const PointLayout = ({endpoint})=> {
    const {t} = useTranslation()
    let history = useHistory();
    const [data, setData] = useState({});
    const [pointInfo, setPointInfo] = useState({});
    const [region, setRegion] = useState({});
    const [city, setCity] = useState({});
    const [district, setDistrict] = useState({});
    const [value,setValue]=useState("");
    let { point_id } = useParams();
    let isLoading = false;
    let isError = false;
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
        },
    };
    const getPointsStatics = (value) => {
        const config2 = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: "application/json",
            },
            params: {
                display: value
            }
        };
        axios
          .get(`${endpoint}/${point_id}/analysis`, config2)
          .then((response) => {
            setData(response.data);
          })
          .catch((error) => {
            console.log("AXIOS ERROR in getPointStatics: ", error);
            isError = true;
          })
          .finally(() => {
            isLoading = false;
          });
    };
    const getPointsByID = () => {
        axios
          .get(`${endpoint}/${point_id}`, config)
          .then((response) => {
            setPointInfo(response.data.data);
            setDistrict(response.data.data.district);
            setRegion(response.data.data.region);
            setCity(response.data.data.city);
          })
          .catch((error) => {
            console.log("AXIOS ERROR in getPointStatics: ", error);
          })
          .finally(() => {
            isLoading = false;
          });
    };
    useEffect(() => {
        getPointsStatics();
        getPointsByID();
    }, []);
    const handleChange = ({ value }) => {
        if (value === 'Today') {
            console.log(`Click on item ${value}`);
            getPointsStatics('today');
        }
        if (value === 'Month') {
            console.log(`Click on item ${value}`);
            getPointsStatics('month');
        }
        if (value === 'Year') {
            console.log(`Click on item ${value}`);
            getPointsStatics('year');
        }
    }

    return (
      <div className= "Overview-container container">
        <ConfigProvider direction={i18next.dir()}>
            <div style={{ marginBottom: 16 }}>
                <Row justify="end">
                    <Select
                        labelInValue
                        defaultValue={{ value: 'Today' }}
                        style={{ width: 120 }}
                        onChange={handleChange}
                    >
                        <Option value="Today">{t("filter.today")}</Option>
                        <Option value="Month">{t("filter.month")}</Option>
                        <Option value="Year">{t("filter.year")}</Option>
                    </Select>
                </Row>
            </div>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                            <StyeldPageHeader
                                style={{borderRadius: '7px'}}
                                className="site-page-header-responsive bg_light"
                                onBack={() => history.goBack()}
                                title={pointInfo.name}
                                subTitle={
                                    <span>
                                        <ul className="flex-container space-between">
                                            <li className="icons-style mg-lr-5px">
                                                <EnvironmentOutlined className="icons-style"/>
                                                {i18next.dir()==="rtl"? `${district.name_ar} - ${region.name_ar}, ${city.name_ar}.`
                                                :`${district.name_en} - ${region.name_en}, ${city.name_en}.`}
                                            </li>
                                            <li className="mg-lr-5px">
                                                <PhoneOutlined className="icons-style"/>+966 {pointInfo.phone}
                                            </li>
                                         </ul>
                                    </span>   
                                }
                                extra={[
                                    pointInfo.isActive === 0? 
                                    <Tag color="volcano" key={true}>Inactive</Tag>:
                                    <Tag color="success" key={true}>Active</Tag>,
                                    <Button type="text" icon={<MoreOutlined/>}/>,
                                ]}
                            />
                </Col>
            </Row>      
            <div>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <Card 
                            style={{borderRadius: '7px'}}  bordered={false}>
                            <h3> 
                                <img src={trackingBlue}/>
                                <strong className="blue_light">{t("PointStatus.total_order")}</strong>
                            </h3>
                            <div className="cd-content">
                            {/* <Statistic className="mg-10px" style={{fontSize: '5px'}} value={112893} /> */}
                                <h3>{data.total_orders}</h3>
                                <h3>{t("Orders.2")+ " "+ data.total_orders}</h3>
                            </div>
                        </Card>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <Card 
                                style={{borderRadius: '7px'}}  bordered={false}>
                                <h3>   
                                    <img src={trackingYello}/>
                                    <strong className="warning">{t("PointStatus.on_hold")}</strong>
                                </h3>
                                <div className="cd-content">
                                    <h3>{data.received_orders}</h3>
                                    <h3>{t("PointDetails.1")+ data.received_orders + t("PointDetails.3")}</h3>
                                </div>
                        </Card>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <Card 
                            style={{borderRadius: '7px'}}  bordered={false}>
                            <h3>
                                <img src={trackingGreen}/>
                                <strong className="success">{t("PointStatus.released")}</strong>
                            </h3>
                            <div className="cd-content">
                                <h3>{data.released_orders}</h3>
                                <h3>{t("PointDetails.2")+ data.released_orders + t("PointDetails.4")}</h3>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
            </ConfigProvider>
            <PointReport id={point_id}/>
        </div>
    );
}

const StyeldPageHeader = styled(PageHeader)`
  span.ant-descriptions-item-label.ant-descriptions-item-colon {
    display: block;
    background: white;
    padding: 1em;
    font-size: 16px;
    margin-bottom: 0.8em;
  }
  span.ant-descriptions-item-content {
    font-weight: 600;
    font-size: 15px;
  }
`;
export default PointLayout;