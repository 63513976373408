import {action, observable} from "mobx";
import {TabPayPagination, TabReport} from "../../../../models/TabReport";
import axios from "../../../../axios";
import {createContext} from "react";
import {Payment} from "../../../../models/Payemnt";
import {message} from "antd";

const success = (value: any) => {
  message.success(value);
};

class PaymentReportStore {
  @observable token = localStorage.getItem("token");
  @observable tabReportList: TabReport[] = [];
  @observable paymentBulk: Payment[] = [];
  @observable paymentPagination?: TabPayPagination;

  // Response status
  @observable isLoading: boolean = false;
  @observable isError: boolean = false;
  @observable errors: string = "";

  config = {
    headers: {
      Authorization: `Bearer ${this.token}`,
      Accept: "application/json",
    },
  };
  @action
  fetchPayemntReport = (route: string, request: number) => {
    this.isLoading = true;
    axios
      .get(`/V1/${route}`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response: any) => {
        // @on Success
        switch (request) {
          case 0:
            this.tabReportList = response.data.data;
            this.paymentPagination = response.data;
            break;
        }
        this.isLoading = false;
      })

      .catch((_error: any) => {
        // @On failure
        this.isError = true;
        this.isLoading = false;
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  @action
  handleReminder = (route: string, list: any[]) => {
    this.isLoading = true;
    axios
      .post(`/V1/${route}`, list, this.config)
      .then((response: any) => {
        // @on Success
        switch (response.status.code) {
          case 200:
            success("تم ارسال فاتورة الدفع مجددا");
        }
        this.isLoading = false;
      })

      .catch((error: any) => {
        // @On failure
        this.isError = true;
        this.isLoading = false;
        error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  };
}

export const paymentReportStore = new PaymentReportStore();
const PaymentReportStoreContext = createContext(new PaymentReportStore());
export default PaymentReportStoreContext;
