import React from 'react';
import {Col, Row} from "antd";
import {MyLabel} from "../../../../global-styled-components/Labels";
import {PrimaryInputTextArea, ProfileInput} from "../../../../global-styled-components/Inputs";
import {GhostButton} from "../../../../global-styled-components/Buttons";
import {useTranslation} from "react-i18next";
import {getIn} from "formik";

function PointInfoAddressForm(props) {
    const { t } = useTranslation();

    return (

        <Row gutter={[16,16]}>
            <Col xs={24} >
                <MyLabel>{t("Address.1")}</MyLabel>
                <ProfileInput
                    name="location.address"
                    value={props.values.location.address}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    placeholder={t("Address.1")}
                />
                {getIn(props.errors, 'location.address') && getIn(props.errors, 'location.address') ? (
                    <div className="error-message">{getIn(props.errors, 'location.address')}</div>
                ) : null}


            </Col>
            <Col xs={24} className={"marg-v-1"}>
                <MyLabel>{t("Map.label")}</MyLabel>
                <ProfileInput
                    name="location.title"
                    value={props.values.location.title}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    placeholder={t("Map.label")}
                />
                {getIn(props.touched, 'location.title') && getIn(props.errors, 'location.title') ? (
                    <div className="error-message">{getIn(props.errors, 'location.title')}</div>
                ) : null}
            </Col>

            <Col xs={12} className={"marg-v-1"}>
                <MyLabel>{t("Map.lat")}</MyLabel>
                <ProfileInput
                    name="location.latitude"
                    value={props.values.location.latitude}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    placeholder={t("Map.lat")}
                />
                {getIn(props.touched, 'location.latitude') && getIn(props.errors, 'location.latitude') ? (
                    <div className="error-message">{getIn(props.errors, 'location.latitude')}</div>
                ) : null}
            </Col>
            <Col xs={12} className={"marg-v-1"}>
                <MyLabel>{t("Map.lng")}</MyLabel>
                <ProfileInput
                    name="location.longitude"
                    value={props.values.location.longitude}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    placeholder={t("Map.lng")}
                />
                {getIn(props.touched, 'location.longitude') && getIn(props.errors, 'location.longitude') ? (
                    <div className="error-message">{getIn(props.errors, 'location.longitude')}</div>
                ) : null}

                <Row justify="end" className="marg-v-1">
                    <GhostButton   onClick={async ()=>{
                        let url= props.values.map_link
                        let regex = new RegExp('@(.*),(.*),');
                        let lon_lat_match = url.match(regex);
                        if(lon_lat_match){

                            props.setFieldValue("location.latitude",lon_lat_match[1])
                            props.setFieldValue("location.longitude",lon_lat_match[2])
                        }
                    }} >
                        Generate Coordinates
                    </GhostButton>
                </Row>
            </Col>

            <Col xs={24} className={"marg-v-1"}>
                <MyLabel>{t("Map.2")}</MyLabel>
                <ProfileInput
                    name="location.map_link"
                    value={props.values.location.map_link}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    maxLength={1000}
                    placeholder={t("Map.1")}
                />
                {getIn(props.touched, 'location.map_link') && getIn(props.errors, 'location.map_link') ? (
                    <div className="error-message">{getIn(props.errors, 'location.map_link')}</div>
                ) : null}
                <Row justify="end" className="marg-v-1">
                    <GhostButton   onClick={async ()=>{
                        // values.map_link =  `https://maps.google.com/maps?q=${values.latitude},${values.longitude}`
                        props.setFieldValue("location.map_link",`https://maps.google.com/maps?q=${props.values.latitude},${props.values.longitude}`)
                    }} >
                        Generate link
                    </GhostButton>
                </Row>

            </Col>

            <Col xs={24} className={"marg-v-1"}>
                <MyLabel>ملاحظات</MyLabel>
                <PrimaryInputTextArea
                    name="comment"

                    value={props.values.location.comment}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    placeholder="اضافة ملاحظات"

                />
                {getIn(props.touched, 'location.comment') && getIn(props.errors, 'location.comment') ? (
                    <div className="error-message">{getIn(props.errors, 'location.comment')}</div>
                ) : null}
            </Col>

        </Row>
    );
}

export default PointInfoAddressForm;