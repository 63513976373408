import React, { FC } from "react";
import noulLogo from "../../../noul-logo-sm.png";
import { Formik } from "formik";
import { Form } from "antd";

import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { MyLabel } from "../../global-styled-components/Labels";
import { PasswordPrimaryInput } from "../../global-styled-components/Inputs";
import { PrimaryButton } from "../../global-styled-components/Buttons";

interface OwnProps {
  user: any;
  isLoading: boolean;
  actionForgetPassword: (values:any) => void;
}

type Props = OwnProps;

const ResetPasswordForm: FC<Props> = ({
  user,
  isLoading,
  actionForgetPassword,
}) => {
  const { t } = useTranslation();
  // Yup Validations
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, t("PasswordValidate.1"))
      .max(64)
      .required(t("InputRequired.1")),
    password_confirmation: Yup.string()
      .min(8, t("PasswordValidate.1"))
      .max(64)
      .required(t("InputRequired.1"))
      .oneOf([Yup.ref("password")], t("PasswordMatch.1")),
  });
  return (
    <div className="password-reset">
      <img
        width={130}
        src={noulLogo}
        className="centered"
        style={{ marginBottom: "4em" }}
      />
      <h2
        style={{
          textAlign: "center",
          fontWeight: 700,
          marginBottom: "10dp",
        }}
      >
        {t("ResetPassword.1")}
      </h2>
      <Formik
        initialValues={user}
        onSubmit={(values, { setSubmitting}) => {
          setSubmitting(true);
          actionForgetPassword(values);
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form className="forget-password-form">
            <Form.Item>
              <MyLabel>{t("Password.3")}</MyLabel>
              <PasswordPrimaryInput
                placeholder="••••••••"
                type="password"
                name="password"
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                style={{ marginBottom: 0 }}
              />

              {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
              {touched.password && errors.password ? (
                <div className="error-message">{errors.password}</div>
              ) : null}
            </Form.Item>

            <Form.Item>
              <MyLabel>{t("Password.2")}</MyLabel>
              <PasswordPrimaryInput
                placeholder="••••••••"
                type="password"
                name="password_confirmation"
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password_confirmation}
                style={{ marginBottom: 0 }}
              />

              {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
              {touched.password_confirmation && errors.password_confirmation ? (
                <div className="error-message">
                  {errors.password_confirmation}
                </div>
              ) : null}
            </Form.Item>

            <PrimaryButton
              htmlType="submit"
              onClick={() => handleSubmit()}
              style={{ width: "inherit" }}
              loading={isLoading}
            >
              {t("ResetPassword.2")}
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPasswordForm;
