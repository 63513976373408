import React, {useContext, useEffect, useState} from "react";
import {Formik} from "formik";
import {useTranslation} from "react-i18next";
import {Col, Form, Modal, PageHeader, Result, Row} from "antd";
import styled from "styled-components";
import {GhostButton, PrimaryButton,} from "../../global-styled-components/Buttons";
import {observer} from "mobx-react-lite";
import AuthStoreContext from "../../../stores/AuthStore";
import * as Yup from "yup";
import {LoadingOutlined} from "@ant-design/icons";
import RegisterStoreContext from "../registerStore";
import AccountFields from "../../Admin-Dashboard/add-account/new-account-form/account-fields/account-fields";
import LocationFields from "../../Admin-Dashboard/add-account/new-account-form/account-fields/location-fields";
import {Link} from "react-router-dom";
import {AnimatePresence, motion} from "framer-motion";

const MyContainer = styled.div`
  max-width: 640px;
  margin: 2em auto;
  padding-inline: 2em;
  alignment: center;
  align-items: center;
  background: white;
  @media (max-width: 768px) {
    max-width: inherit;
    margin-bottom: 0;
    margin-top: 30em !important;
    padding: 3em 2em !important;
  }
`;

type NewAccountProps = {};

const NewAccountForm: React.FC<NewAccountProps> = observer(() => {
    const {t} = useTranslation();
    const authStore = useContext(AuthStoreContext);
    const registerStore = useContext(RegisterStoreContext);
    const [activeForm, setActiveForm] = useState(true);
    const [activeValSchema, setActiveValSchema] = useState(0);
    const [registrationModal,setRegistrationModal] = useState(false)
    const handleModal = ()=>{
        setRegistrationModal(!registrationModal)
    }

    // User @object
    const account = {
        email: "",
        password: "",
        password_confirmation: "",
        name: "",
        city_id: "",
        region_id: "",
        district_id: "",
        address: "",
        phone: "",
        role_id: "",
        isActive: "",
        isRegister: "1",
    }
    account.role_id = "2";
    account.isActive = "0";

    useEffect(() => {
        if (registerStore.region.length === 0) {
            registerStore.getRegion();
        }
    }, []);

    // Validations
    const phoneRegExp = new RegExp(/(5)[0-9]{8}/);
    const AccountSchema = Yup.object().shape({
        email: Yup.string()
            .email(t("EmailValidation.1"))
            .max(100, "*Email must be less than 100 characters")
            .required(t("InputRequired.1")),
        password: Yup.string()
            .min(4, t("FourChartValidation.1"))
            .max(32)
            .required(t("InputRequired.1")),
        password_confirmation: Yup.string()
            .min(4, t("FourChartValidation.1"))
            .max(32)
            .required(t("InputRequired.1"))
            .oneOf([Yup.ref("password")], t("PasswordMatch.1")),
        name: Yup.string()
            .min(4, t("FourChartValidation.1"))
            .max(32)
            .required(t("InputRequired.1")),
        phone: Yup.string()
            .matches(phoneRegExp, t("PhoneRegValidation.1"))
            .required(t("InputRequired.1")),
    });
    const AccountSchema2 = Yup.object().shape({
        district_id: Yup.string().required(t("InputRequired.1")),

        city_id: Yup.string().required(t("InputRequired.1")),

        address: Yup.string()
            .min(4, t("FourChartValidation.1"))
            .max(128)
            .required(t("InputRequired.1")),

        phone: Yup.string()
            .matches(phoneRegExp, t("PhoneRegValidation.1"))
            .required(t("InputRequired.1")),
    });

    const validation = [AccountSchema, AccountSchema2];

    return (
        <MyContainer>
            <PageHeader
                title={() => {
                    return <Link to="/Login">{t("Back to login")}</Link>;
                }}
            />

            <h1
                style={{
                    fontWeight: 700,
                }}
            >
                {t("RegisterTo.1")}{" "}
                <abbr className="text-color-primary"> {t("Noul.1")} </abbr>
                {t("AsSeller.1")}
            </h1>

            <Formik
                initialValues={account}
                validationSchema={validation[activeValSchema]}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    if (activeForm) {
                        setActiveForm(!activeForm);
                        setActiveValSchema(1);
                        setSubmitting(false);
                    } else {
                        setSubmitting(true);
                        authStore.addAccount(
                            values,
                            setSubmitting,
                            resetForm,
                            "auth/register",
                            0,
                            handleModal
                        );
                    }
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                  }) => (
                    <Form>
                        {/*1- Name Input*/}
                        <Row gutter={[16, 16]}>
                            {/*5- Region Input*/}
                            <AnimatePresence>
                                {activeForm ? (
                                    <motion.div
                                        initial={{opacity: 0}}
                                        animate={{opacity: 1}}
                                        transition={{duration: 0.6, ease: "easeInOut"}}
                                    >
                                        <AccountFields
                                            handleChange={handleChange}
                                            values={values}
                                            handleBlur={handleBlur}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </motion.div>
                                ) : (
                                    <motion.div
                                        initial={{opacity: 0}}
                                        animate={{opacity: 1}}
                                        transition={{duration: 0.6, ease: "easeInOut"}}
                                    >
                                        <LocationFields
                                            handleChange={handleChange}
                                            values={values}
                                            handleBlur={handleBlur}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </motion.div>
                                )}
                            </AnimatePresence>
                            {activeForm ? (
                                <Col sm={24}>
                                    <PrimaryButton
                                        className="login-form-button"
                                        onClick={() => handleSubmit()}
                                    >
                                        {t("Continue.1")}
                                    </PrimaryButton>
                                </Col>
                            ) : (
                                <>
                                    <Col sm={12}>
                                        <GhostButton
                                            className="login-form-button"
                                            onClick={() => {
                                                setActiveForm(!activeForm);
                                                setActiveValSchema(0);
                                            }}
                                        >
                                            {t("Previous.1")}
                                        </GhostButton>
                                    </Col>
                                    <Col sm={12}>
                                        <PrimaryButton
                                            className="login-form-button"
                                            htmlType="submit"
                                            onClick={() => handleSubmit()}
                                        >
                                            {t("CreateAccount.1")}
                                            {isSubmitting ? (
                                                <LoadingOutlined style={{margin: "0.5em"}}/>
                                            ) : null}
                                        </PrimaryButton>
                                    </Col>
                                </>
                            )}
                        </Row>
                        <Link to="/login">{t("Regiester_here.2")}</Link>
                    </Form>
                )}
            </Formik>

            <Modal
                visible={registrationModal}
                width={"50%"}
                destroyOnClose={true}
                footer={null}
                closable={true}
                onCancel={() => handleModal()}
                afterClose={() => {
                    authStore.seller_reg_status = false;
                }}
            >
                <Result
                    status="success"
                    title="سعيدون بانضمامك إلى نول"
                    subTitle="حسابك تحت المراجعة حاليا وسيتم التواصل معك قريبا لتفعيله"
                    extra={[
                        <Link to="/login" onClick={() => authStore.seller_reg_status = false}> <PrimaryButton type="primary" key="console">
                            الرئيسية
                        </PrimaryButton>,</Link>


                    ]}
                />
            </Modal>
        </MyContainer>
    );
});
export default NewAccountForm;
